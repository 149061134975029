import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiGetRequest } from '../../services/base';
axios.defaults.withCredentials = true;

export const startPage = createSlice({
    name: 'startPage',
    initialState: {
        count: null,
        today: null,
        yesterday: null,
        week: null,
        graph: null,
        graphWeek: null,
        graphWeekVals: null,
    },
    reducers: {
        setCount: (state, action) => {
            state.count = action.payload
        },
        setToday: (state, action) => {
            state.today = action.payload
        },
        setYesterday: (state, action) => {
            state.yesterday = action.payload
        },
        setWeek: (state, action) => {
            state.week = action.payload
        },
        setGraph: (state, action) => {
            state.graph = action.payload
        },
        setGraphWeek: (state, action) => {
            state.graphWeek = action.payload
        },
        setGraphWeekVals: (state, action) => {
            state.graphWeekVals = action.payload
        },
    }
})

export const { setCount, setToday, setYesterday, setWeek, setGraph, setGraphWeek, setGraphWeekVals } = startPage.actions

export const getCount = (code) => (dispatch, getState) => {
    const params = {
        url: `/api/shops/${code}/products?flag=count`,
        callBack: setCount
    }

    dispatch(apiGetRequest(params))
}

export const setPeriod = (data) => (dispatch, getState) => {
    switch(data.period) {
        case 'today':
            dispatch(setToday(data))
            break;
        case 'yesterday':
            dispatch(setYesterday(data))
            break;
        case 'week':
            dispatch(setWeek(data))
            break;
        default:
            //console.log(data.period)
    }
}

export const getPeriod = (code, period) => (dispatch, getState) => {
    const params = {
        url: `/api/shops/${code}/statistic/count?period=${period}`,
        callBack: setPeriod
    }

    dispatch(apiGetRequest(params))
}

export const getGraph = (code) => (dispatch, getState) => {
    const params = {
        url: `/api/shops/${code}/statistic/graph`,
        callBack: setGraph
    }

    dispatch(apiGetRequest(params))
}

export const getGraphWeek = (code) => (dispatch, getState) => {
    const params = {
        url: `/api/shops/${code}/statistic/week`,
        callBack: setGraphWeek
    }

    dispatch(apiGetRequest(params))
}

export default startPage.reducer