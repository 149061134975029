import { Button, Paper, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateProduct } from "../../../store/settings/product"

const Title = ({id}) => {

    const dispatch = useDispatch()

    const product = useSelector((state) => state.settingsProduct.data)

    const [title, setTitle] = useState(product.title)
    const [comment, setComment] = useState(product.comment ? product.comment : "")

    return (
        <Paper sx={{p: 3, mb: 4}} elevation={0}>
            <Typography variant="h6" sx={{mb:3}}>Общие настройки</Typography>
            <TextField
                id="pro-title"
                value={title}
                fullWidth
                sx={{mb: 2}}
                label="Название"
                onChange={e => setTitle(e.target.value)}
                variant="outlined" />
            <TextField
                id="pro-title"
                value={comment}
                fullWidth
                sx={{mb: 2}}
                label="Комментарий"
                multiline
                rows={3}
                onChange={e => setComment(e.target.value)}
                variant="outlined" />
            <Button variant="contained" onClick={() => dispatch(updateProduct(id, title, comment))}>Сохранить</Button>
        </Paper>
    )
}

export default Title