import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiGetRequest } from '../../services/base';
axios.defaults.withCredentials = true;

export const shopSales = createSlice({
    name: 'shopSales',
    initialState: {
        sales: null,
        sort: 1,
        min: 0,
        max: 100,
        sale: null
    },
    reducers: {
        setSales: (state, action) => {
            state.sales = action.payload
        },
        setSort: (state, action) => {
            state.sort = action.payload
        },
        setMin: (state, action) => {
            state.min = action.payload
        },
        setMax: (state, action) => {
            state.max = action.payload
        },
        setSaleData: (state, action) => {
            state.sale = action.payload
        }
    }
})

export const { setSales, setSort, setMin, setMax, setSaleData } = shopSales.actions

export const getSales = (code, f, t) => (dispatch, getState) => {

    const params = {
        url: `/api/shops/${code}/sales?from=${f}&to=${t}`,
        callBack: setSales
    }

    dispatch(apiGetRequest(params))
}

export const getSale = (code, id) => (dispatch, getState) => {

    const params = {
        url: `/api/shops/${code}/sales/${id}`,
        callBack: setSaleData
    }

    dispatch(apiGetRequest(params))
}

export default shopSales.reducer