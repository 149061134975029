import { Box, Typography } from "@mui/material"
import Preloader from "../../components/Preloader"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCountSales } from "../../store/shops/counts/countSales";

const HomeShopPeriod = ({label, period, code}) => {

    const dispatch = useDispatch();

    const count = useSelector((state) => state.countSales[code])

    useEffect(() => {
        dispatch(getCountSales(code, period))
    }, [dispatch, code, period])

    return (
        <Box>
            <Typography variant="h6" fontWeight={600} sx={{fontSize: 14}}>{label}</Typography>
            <Box sx={{position: 'relative'}}>
                {count && count[period] ?
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography variant="caption" sx={{color: '#777'}}>Продаж</Typography>
                            <Typography variant="h5" fontWeight={600}>{count[period].count.toLocaleString('ru-RU')}</Typography>
                        </Box>
                        <Box sx={{width: '50%'}}>
                            <Typography variant="caption" sx={{color: '#777'}}>На сумму</Typography>
                            <Typography variant="h5" fontWeight={600}>{count[period].pay.toLocaleString('ru-RU')}</Typography>
                        </Box>
                    </Box> : <Preloader/>}
            </Box>
        </Box>
    )
}

export default HomeShopPeriod