import { useDispatch, useSelector } from "react-redux"
import PageTitle from "../../../components/Pages/PageTitle"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { getProduct, setData } from "../../../store/settings/product"
import { Grid } from "@mui/material"
import Title from "./Title"
import Preloader from "../../../components/Preloader"
import Images from "./Images"
import Cost from "./Cost"
import Links from "./Links"
import { getServices } from "../../../store/services/services"
import PricerWB from "./PricerWB"
import PricerOZ from "./PricerOZ"
import PricerYM from "./PricerYM"

const SellerProduct = () => {

    const {id} = useParams()

    const dispatch = useDispatch()

    const product = useSelector((state) => state.settingsProduct.data)
    const services = useSelector((state) => state.services.services)

    useEffect(() => {
        dispatch(getProduct(id))
    }, [id, dispatch])

    useEffect(() => {
        dispatch(setData(null))
    }, [dispatch])

    useEffect(() => {
        dispatch(getServices())
    }, [dispatch])

    if (!product) {
        return <Preloader />
    }

    return (
        <>
            <PageTitle title={product ? product.title : "Товар"} back="/settings/products" backLabel="Мои товары" />
            <Grid container spacing={4}>
                <Grid item xs={8}>
                    <Title id={id} />
                    <Links id={id} />
                    <Images id={id} />
                </Grid>
                <Grid item xs={4}>
                    <Cost id={id} />
                    {services && services.PricerWB ? <PricerWB productID={id} /> : null}
                    {services && services.PricerOZ ? <PricerOZ productID={id} /> : null}
                    {services && services.PricerYM ? <PricerYM productID={id} /> : null}
                </Grid>
            </Grid>
        </>
    )
}

export default SellerProduct