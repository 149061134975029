import { Grid, Typography } from "@mui/material"
import LinkList from "./LinkList"
import UnsortedList from "./UnsortedList"
import PageTitle from "../../../../components/Pages/PageTitle"
import { useDispatch, useSelector } from "react-redux"
import { getProductsList, getUnsortedList, setUnsorted } from "../../../../store/settings/products"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const Linker = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const code = useSelector((state) => state.settingsProducts.code)

    useEffect(() => {
        if (!code) {
            navigate('/settings/products')
        } else {
            dispatch(getUnsortedList(code))
            dispatch(getProductsList())
        }
    }, [code, dispatch, navigate])

    useEffect(() => {
        return () => {
            dispatch(setUnsorted(null))
        }
    }, [dispatch])

    const getTitle = () => {
        switch(code) {
            case 'wb':
                return "Wildberries"
            case 'oz':
                return "Ozon"
            case 'ym':
                return "YandexMarket"
            default:
                return null
        }
    }

    return (
        <>
            <PageTitle title={`Настройка товаров ${getTitle()}`} back='/settings/products' backLabel="Мои продукты" />
            <Grid container spacing={4} sx={{mb: 7}}>
                <Grid item xs={6}>
                    <Typography variant="h6" sx={{mb: 2}}>Мои товары</Typography>
                    <LinkList />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" sx={{mb: 2}}>Не распределенные</Typography>
                    <UnsortedList />
                </Grid>
            </Grid>
        </>
    )
}

export default Linker