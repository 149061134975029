import { createSlice } from '@reduxjs/toolkit'
import { apiGetRequest } from '../services/base';

export const news = createSlice({
    name: 'news',
    initialState: {
        news: []
    },
    reducers: {
        setNews: (state, action) => {
            state.news = action.payload
        }
    }
})

export const { setNews } = news.actions

export const getNews = () => (dispatch, getState) => {
    const params = {
        url: '/api/news',
        callBack: setNews
    }

    dispatch(apiGetRequest(params))
}

export default news.reducer