import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiGetRequest, apiPostRequest } from '../../services/base';
axios.defaults.withCredentials = true;

export const margin = createSlice({
    name: 'margin',
    initialState: {
        value: null
    },
    reducers: {
        setValue: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setValue } = margin.actions

export const getNalog = () => (dispatch, getState) => {
    const params = {
        url: `/api/seller/nalog`,
        callBack: setValue
    }

    dispatch(apiGetRequest(params))
}

export const saveNalog = (nalog) => (dispatch, getState) => {

    const data = new FormData()
    data.append("nalog", nalog)

    const params = {
        url: `/api/seller/nalog`,
        callBack: getNalog,
        data: data
    }

    dispatch(apiPostRequest(params))
}

export default margin.reducer