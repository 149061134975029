import { Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import Preloader from "../../../../components/Preloader"

import { useDispatch, useSelector } from "react-redux";
import { getGraphWeek } from "../../../../store/shops/graphics/graphicWeek";
import Progress from "../../../../components/Progress";

const WeekGarph = ({code}) => {

    const dispatch = useDispatch()

    const [values, setValues] = useState(null);

    const type = useSelector((state) => state.graphicType.value)
    const graph = useSelector((state) => state.graphicWeek[code])

    useEffect(() => {
        dispatch(getGraphWeek(code))
    }, [dispatch, code])

    useEffect(() => {
        if (graph) {
            setValues(graph[type])
        }
    }, [graph, type])

    return (
        <Paper sx={{position: 'relative', mt: 1, p: 3}} elevation={0}>
            <Typography variant="h5" fontWeight={500} sx={{mb: 2}}>
                Прогресс этой недели
            </Typography>
            {values ? <Progress values={values} /> : <Preloader />}
        </Paper>
    )
}

export default WeekGarph