import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProductsCount } from "../../../../store/settings/products"
import { Paper, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import Preloader from "../../../../components/Preloader"

const SellerProductsCount = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const count = useSelector((state) => state.settingsProducts.count)

    const [alert, setAlert] = useState(false)

    useEffect(() => {
        dispatch(getProductsCount())
    }, [dispatch])

    useEffect(() => {
        if (count) {
            if (count.alerts.length > 0) {
                setAlert(true)
            }
        }
    }, [count])

    const ShopsCount = () => {
        return (
            <>
                <Typography 
                    variant="h6" 
                    fontWeight={600} 
                    color={ alert ? "#fff" : "#000" } >
                    WB — {count.wbCount}
                </Typography>
                <Typography 
                    variant="h6" 
                    fontWeight={600} 
                    color={ alert ? "#fff" : "#000" } >
                    Ozon — {count.ozCount}
                </Typography>
                <Typography 
                    variant="h6" 
                    fontWeight={600} 
                    color={ alert ? "#fff" : "#000" } >
                    YM — {count.ymCount}
                </Typography>
            </>
        )
    }

    const ProductsCount = () => {
        return (
            <>
                <Typography 
                    variant="h1" 
                    fontWeight={600} 
                    color={ alert ? "#fff" : "primary" } >
                    {count.count}
                </Typography>
                <ShopsCount />
            </>
        )
    }

    return (
        <Paper 
            sx={{p: 3, position: 'relative', minHeight: 250, cursor: "pointer", backgroundColor: alert ? "red" : "#fff"}}
            onClick={() => navigate('products')}
            elevation={0} >
            <Typography variant="h4" sx={{mb: 2, color: alert ? "#fff" : "#000"}}>
                Товары
            </Typography>
            {count ? <ProductsCount /> : <Preloader /> }
        </Paper>
    )
}

export default SellerProductsCount