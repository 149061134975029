import { useNavigate } from "react-router-dom";
import { Box, Button, Divider, Typography } from "@mui/material";
import { baseURL } from "../../services/base";
import HomeShopPeriod from "./HomeShopPeriod";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getShopData } from "../../store/shops/shop/shopData";

const HomeShop = ({shop}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const shopData = useSelector((state) => state.shopData[shop.code])

    //console.log(shop)
    //console.log(shopData)

    useEffect(() => {
        if (shopData === null) {
            dispatch(getShopData(shop.code))
        }
    }, [shop, dispatch, shopData])

    const handleClick = () => {
        if (shopData && shopData.active === 1) {
            navigate(`/shops/${shopData.code}`)
        }
    }

    return (
        <Box
            onClick={handleClick}
            sx={{
                backgroundColor: '#fff',
                p: 4,
                m: 2,
                borderRadius: 2,
                boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                width: 200,
                cursor: shopData && shopData.active === 1 ? 'pointer' : 'default',
                transition: '.5s',
                '&:hover': {
                    boxShadow: '0 0 15px 10px rgba(0, 0, 0, 0.05)',
                },
            }}>
                <Box sx={{height: 100, mb: 3}}>
                    {shop.logo ? 
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
                            <img style={{width : 100}} src={baseURL + `${shop.logo}`} alt={shop.title} />
                        </Box> : 
                        <Box>
                            <Typography variant="h4" sx={{textAlign: 'center'}}>
                                {shop.title}
                            </Typography>
                        </Box>
                    }
                </Box>

                {shopData && shopData.active === 1 ? <ActiveShop shopData={shopData} /> : <PrevShop />}
        </Box>
    )
}

export default HomeShop

const ActiveShop = ({shopData}) => {

    return (
        <>
            {shopData && shopData.sellerData && shopData.sellerData.auth ? <CountSales code={shopData.code} /> : <AuthNeed code={shopData.code} />}
            <Box sx={{textAlign: 'center', mt: 3}}>
                <Button>Подробнее</Button>
            </Box>
        </>
    )
}

const CountSales = ({code}) => {
    return (
        <Box sx={{position: 'relative', height: 250}}>
            <HomeShopPeriod label="Сегодня" period={'today'} code={code} />
            <Divider sx={{my: 1}}/>
            <HomeShopPeriod label="Вчера" period={'yesterday'} code={code} />
            <Divider sx={{my: 1}}/>
            <HomeShopPeriod label="С начала недели" period={'week'} code={code} />
        </Box>
    )
}

const AuthNeed = () => {
    return (
        <Box sx={{height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography textAlign='center'>Нет авторизации</Typography>
        </Box>
    )
}

const PrevShop = () => {
    return (
        <Box sx={{height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography textAlign='center'>В разработке</Typography>
        </Box>
    )
}