import { Box, Button, FormControl, OutlinedInput } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { saveOZToken } from "../../../store/shops/ozTokens"

const OzonAuth = () => {

    const dispatch = useDispatch()

    const [clientID, setClientID] = useState("")
    const [clientKey, setClientKey] = useState("")

    const handleSubmit = () => {
        dispatch(saveOZToken(clientID, clientKey))
    }

    return (
        <Box sx={{margin: '0px auto', width: 720}}>
            <Box display='flex' sx={{alignItems: 'center', mb: 2}}>
                <Box sx={{mr: 3, width: 100, textAlign: 'right'}}>
                    Client ID
                </Box>
                <FormControl sx={{ width: '45ch' }} variant="outlined">
                    <OutlinedInput
                        id="name"
                        type='text'
                        value={clientID}
                        onChange={e => setClientID(e.target.value)}
                    />
                </FormControl>
            </Box>
            <Box display='flex' sx={{alignItems: 'center', mb: 2}}>
                <Box sx={{mr: 3, width: 100, textAlign: 'right'}}>
                    API Key
                </Box>
                <FormControl sx={{ width: '45ch' }} variant="outlined">
                    <OutlinedInput
                        id="name"
                        type='text'
                        value={clientKey}
                        onChange={e => setClientKey(e.target.value)}
                    />
                </FormControl>
            </Box>
            <Box sx={{paddingLeft: '124px'}}>
                <Button disabled={clientID.trim() === "" || clientKey.trim() === ""} onClick={handleSubmit} variant="contained">Сохранить</Button>
            </Box>
        </Box>
    )
}

export default OzonAuth