import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiGetRequest } from '../../services/base';
axios.defaults.withCredentials = true;

export const shopOZ = createSlice({
    name: 'shopOZ',
    initialState: {
        shop: null,
        count: null,
        today: null,
        yesterday: null,
        week: null,
        graph: null,
        graphWeek: null,
        graphWeekVals: null,
        lastUpdate: null
    },
    reducers: {
        setShopOZ: (state, action) => {
            state.shop = action.payload
        },
        setCountOZ: (state, action) => {
            state.count = action.payload
        },
        setTodayOZ: (state, action) => {
            state.today = action.payload
        },
        setYesterdayOZ: (state, action) => {
            state.yesterday = action.payload
        },
        setWeekOZ: (state, action) => {
            state.week = action.payload
        },
        setGraphOZ: (state, action) => {
            state.graph = action.payload
        },
        setGraphWeekOZ: (state, action) => {
            state.graphWeek = action.payload
        },
        setGraphWeekValsOZ: (state, action) => {
            state.graphWeekVals = action.payload
        },
        setLastUpdateOZ: (state, action) => {
            state.lastUpdate = action.payload
        },
    }
})

export const { setShopOZ, setCountOZ, setTodayOZ, setYesterdayOZ, setWeekOZ, setGraphOZ, setGraphWeekOZ, setGraphWeekValsOZ, setLastUpdateOZ } = shopOZ.actions

export const getShopOZ = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/oz`,
        callBack: setShopOZ
    }

    dispatch(apiGetRequest(params))

    let date = new Date()
    dispatch(setLastUpdateOZ(date.getTime()))
}

export const getCountOZ = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/oz/products?flag=count`,
        callBack: setCountOZ
    }

    dispatch(apiGetRequest(params))
}

export const setPeriodOZ = (data) => (dispatch, getState) => {
    switch(data.period) {
        case 'today':
            dispatch(setTodayOZ(data))
            break;
        case 'yesterday':
            dispatch(setYesterdayOZ(data))
            break;
        case 'week':
            dispatch(setWeekOZ(data))
            break;
        default:
            //console.log(data.period)
    }
}

export const getPeriodOZ = (period) => (dispatch, getState) => {
    const params = {
        url: `/api/shops/oz/statistic/count?period=${period}`,
        callBack: setPeriodOZ
    }

    dispatch(apiGetRequest(params))
}

export const getGraphOZ = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/oz/statistic/graph`,
        callBack: setGraphOZ
    }

    dispatch(apiGetRequest(params))
}

export const getGraphWeekOZ = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/oz/statistic/week`,
        callBack: setGraphWeekOZ
    }

    dispatch(apiGetRequest(params))
}

export default shopOZ.reducer