import { Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import Preloader from "../../components/Preloader";
import Progress from "../../components/Progress";
import { getGraphHomeWeek } from "../../store/shops/graphics/grahicHomeWeek";

const HomeWeek = () => {

    const dispatch = useDispatch()

    const type = useSelector((state) => state.graphicType.value)
    const data = useSelector((state) => state.grahicHomeWeek.data)

    const [values, setValues] = useState(null);

    useEffect(() => {
        dispatch(getGraphHomeWeek())
    }, [dispatch])

    useEffect(() => {
        if (data) {
            setValues(data[type])
        }
    }, [data, type])

    return (
        <Paper sx={{position: 'relative', mt: 1, p: 3}} elevation={0}>
            <Typography variant="h6" fontWeight={500} sx={{mb: 2}}>
                Прогресс этой недели
            </Typography>

            {values ? <Progress values={values} /> : <Preloader />}
        </Paper>
    )
}

export default HomeWeek