import { Box, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useState } from "react";
import { logout } from "../../../store/auth";
import Alerts from "../../Alerts";

const User = () => {

    const dispatch = useDispatch()

    const user = useSelector((state) => state.auth.user)

    const PlaceHolder = () => {
        return (
            <Box display='flex' sx={{ color: '#cccccc' }}>
                <Box sx={{ mr: 1}}>
                    <AccountCircleIcon />
                </Box>
                <Typography>
                    User
                </Typography>
            </Box>
        )
    }

    const UserComp = () => {

        const [anchorEl, setAnchorEl] = useState(null);
      
        const handleMenu = (event) => {
          setAnchorEl(event.currentTarget);
        };
      
        const handleClose = () => {
          setAnchorEl(null);
        };

        const handlerLogout = () => {
            dispatch(logout())
        }

        return (
            <Box display='flex'>
                {/* <Alerts /> */}
                <Box display='flex' onClick={handleMenu} sx={{ cursor: 'pointer' }}>
                    <Box sx={{ mr: 1}}>
                        <AccountCircleIcon color="primary" />
                    </Box>
                    <Typography variant="button">
                        {user.name}
                    </Typography>
                </Box>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    <MenuItem onClick={handleClose}>Профиль</MenuItem>
                    <MenuItem onClick={handlerLogout}>Выйти</MenuItem>
                </Menu>
            </Box>
        )
    }

    return (
        <>
            { user ? <UserComp /> : <PlaceHolder /> }
        </>
    )
}

export default User