import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiPostRequest } from '../../services/base';
import { getShopWB } from './shopWB';
axios.defaults.withCredentials = true;

export const wbTokens = createSlice({
    name: 'wbTokens',
    initialState: {
        save: null
    },
    reducers: {
        setSave: (state, action) => {
            state.save = action.payload
        }
    }
})

export const { setSave } = wbTokens.actions

export const saveTokenResponse = () => (dispatch, getState) => {
    dispatch(getShopWB())
}

export const saveWBToken = (type, token) => (dispatch, getState) => {
    
    const data = new FormData()
    data.append('type', type)
    data.append('token', token)

    const params = {
        url: `/api/shops/wb/token`,
        data: data,
        callBack: saveTokenResponse
    }

    dispatch(apiPostRequest(params))
}

export default wbTokens.reducer