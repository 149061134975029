import PageTitle from "../../components/Pages/PageTitle"
import ShopAuth from "./ShopAuth"
import { useDispatch, useSelector } from "react-redux"
import Preloader from "../../components/Preloader"
import { useEffect } from "react"
import { getShopOZ } from "../../store/shops/shopOZ"
import { Box } from "@mui/material"

const ShopOZ = ({outlet}) => {

    const shop = useSelector((state) => state.shopOZ.shop)

    const dispatch = useDispatch()

    useEffect(() => {
        if (shop === null) {
            dispatch(getShopOZ())
        }
    }, [dispatch, shop])

    if (shop === null) {
        return (
            <Preloader />
        )
    }



    if (shop.sellerData.auth === false) {
        return (
            <ShopAuth code='oz'/>
        )
    } else {
        if (!shop.sellerData.all) {
            return (
                <Box>Сбор информации</Box>
            )
        }
    }

    return (
        <>
            <PageTitle title={shop.title} />
            {outlet}
        </>
    )
} 

export default ShopOZ