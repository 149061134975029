import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiGetRequest } from '../../services/base';
axios.defaults.withCredentials = true;

export const shopWBgraph = createSlice({
    name: 'shopWBgraph',
    initialState: {
        graph: null,
        lastUpdate: null
    },
    reducers: {
        setGraphWB: (state, action) => {
            state.graph = action.payload
        },
        setLastUpdateWB: (state, action) => {
            state.lastUpdate = action.payload
        },
    }
})

export const { setGraphWB, setLastUpdateWB } = shopWBgraph.actions

export const getGraphWB = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/wb/statistic/graph`,
        callBack: setGraphWB
    }

    dispatch(apiGetRequest(params))
}

export default shopWBgraph.reducer