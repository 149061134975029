import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const graphicType = createSlice({
    name: 'graphicType',
    initialState: {
        value: 'count'
    },
    reducers: {
        setGraphType: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setGraphType } = graphicType.actions

export default graphicType.reducer