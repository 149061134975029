import { Box, Button, Paper } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { setGraphType } from "../../store/shops/graphics/graphicType"
import { useEffect } from "react"

const GraphicTypeSwitcher = ({home = false}) => {

    const dispatch = useDispatch()

    const type = useSelector((state) => state.graphicType.value)

    const handlerSwitch = (type) => {
        dispatch(setGraphType(type))
    }

    useEffect(() => {
        if (home && type === 'percent') {
            dispatch(setGraphType('count'))
        }
    }, [])
    
    return (
        <Paper elevation={0} sx={{px: 3, pt: 3}}>
            <Box>
                <Button 
                    sx={{mr: 1}}
                    id="count"
                    onClick={() => handlerSwitch('count')}
                    variant={type === 'count' ? "contained" : "text"}>
                        Количество
                </Button>
                <Button 
                    sx={{mr: 1}}
                    id="pay"
                    onClick={() => handlerSwitch('pay')}
                    variant={type === 'pay' ? "contained" : "text"}>
                        Выплаты
                </Button>
                <Button 
                    sx={{mr: 1}}
                    id="margin"
                    onClick={() => handlerSwitch('margin')}
                    variant={type === 'margin' ? "contained" : "text"}>
                        Маржа
                </Button>
                {home === false ? 
                <Button 
                    sx={{mr: 1}}
                    id="percent"
                    onClick={() => handlerSwitch('percent')}
                    variant={type === 'percent' ? "contained" : "text"}>
                        Маржинальность
                </Button> : null }
            </Box>
        </Paper>
    )
}

export default GraphicTypeSwitcher