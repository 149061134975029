import { Box, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateCost } from "../../../store/settings/product"

const Cost = ({id}) => {

    const product = useSelector((state) => state.settingsProduct.data)
    const dispatch = useDispatch()

    const [active, setActive] = useState(null)

    useEffect(() => {
            const findIndex = product.prices.findIndex(el => el.active === true)
    
            if (findIndex > -1) {
                setActive(product.prices[findIndex].price)
            }
    }, [product])

    const CostForm = () => {

        const [cost, setCost] = useState("")

        return (
            <Box>
                <TextField
                    id="pro-cost"
                    type="number"
                    value={cost}
                    fullWidth
                    sx={{mb: 2}}
                    label="Себестоимость"
                    onChange={e => setCost(e.target.value)}
                    variant="outlined" />
                <Button variant="contained" disabled={cost === ""} onClick={() => dispatch(updateCost(id, cost))}>Сохранить</Button>
            </Box>
        )
    }

    return (
        <Paper sx={{p:3, mb: 4}} elevation={0}>
            <Typography variant="h6" sx={{mb:3}}>Себестоимость</Typography>
            { product.prices.length > 0 && active !== null ? <Typography variant="h5" sx={{mb:3}} fontWeight={600}>Текущая — {active} ₽</Typography> : null }
            <CostForm />
            { product.prices.length > 0 ?
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Себестоимость</TableCell>
                        <TableCell>Дата изменения</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {product.prices.map((el, i) => <TableRow key={i}>
                                                    <TableCell>{el.price}</TableCell>
                                                    <TableCell>{el.datetime}</TableCell>
                                                    </TableRow>)}
                </TableBody>
            </Table> : null }
        </Paper>
    )
}

export default Cost