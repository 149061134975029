import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProductsList } from "../../../../store/settings/products"
import { Badge, Box, Chip, Divider, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import Preloader from "../../../../components/Preloader"
import { baseURL } from "../../../../services/base"
import ListIcon from '@mui/icons-material/List';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';
import { useNavigate } from "react-router-dom"
import styled from "@emotion/styled"

const ProductsList = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const list = useSelector((state) => state.settingsProducts.list)

    const [view, setView] = useState('grid')

    useEffect(() => {
        dispatch(getProductsList())
    }, [dispatch])

    const Row = ({data}) => {
        const StyledBadge = styled(Badge)(({ theme }) => ({
            '& .MuiBadge-badge': {
              right: -10,
              top: 10,
              border: `2px solid ${theme.palette.background.paper}`,
              padding: '0 4px',
            },
          }));

        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {view === 'full-list' ?
                <TableCell width={120}>
                    <img style={{width: '60px'}} src={`${baseURL}${data.image}`} alt={data.title} />
                </TableCell> : null }
                <TableCell sx={{verticalAlign: 'top'}}>
                    <Typography sx={{cursor: 'pointer'}} color='primary' onClick={() => navigate(`/settings/product/${data.id}`)}>
                        {data.title}
                    </Typography>
                    {view === 'full-list' ?
                    <>
                        <Divider sx={{mt: 1}} />
                        <Box sx={{mt:1}}>
                            {data.comment}
                        </Box>
                    </> : null }
                    </TableCell>
                <TableCell>
                    { data.wb.length > 0 ? <StyledBadge sx={{mr: 4}} badgeContent={data.wb.length} color="primary">WB</StyledBadge> : null }
                    { data.oz.length > 0 ? <StyledBadge sx={{mr: 4}} badgeContent={data.oz.length} color="primary">OZON</StyledBadge> : null }
                    { data.ym.length > 0 ? <StyledBadge badgeContent={data.ym.length} color="primary">YM</StyledBadge> : null }
                </TableCell>
                <TableCell>
                    { data.pricers.length > 0 ? data.pricers.map((chip, i) =><Chip sx={{mr: 1}} key={i} label={chip} size="small" color="secondary"/>) : null }
                </TableCell>
                {data.cost ? <>
                    <TableCell>{data.cost} ₽</TableCell>
                    <TableCell>{data.lastCost ? `${data.lastCost} ₽` : "---"}</TableCell>
                    <TableCell>{data.costDays ? data.costDays : "---"}</TableCell>
                    </> : <TableCell colSpan={3}><Typography variant="button" color='error'>Нет себестоимости</Typography></TableCell>}
            </TableRow>
        )
    }

    const ProductsTable = () => {
        return (
            <TableContainer component={Paper} elevation={0} sx={{position: 'relative', minHeight: 100}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {view === 'full-list' ? <TableCell></TableCell> : null }
                            <TableCell>Название</TableCell>
                            <TableCell>Магазины</TableCell>
                            <TableCell>Pricer</TableCell>
                            <TableCell>Себестоимость</TableCell>
                            <TableCell>Предыдущая</TableCell>
                            <TableCell>Изменено</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map(row => <Row key={row.id} data={row} />)}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const Item = ({data}) => {
        return (
            <Paper sx={{flex: '0 0 200px', mr: 2, mb: 2, p:3, display: 'flex', justifyContent:'space-between', flexDirection: 'column', cursor: 'pointer'}} elevation={0} onClick={() => navigate(`/settings/product/${data.id}`)}>
                <Box>
                    <img style={{width: '100%'}} src={`${baseURL}${data.image}`} alt={data.title} />
                    <Typography sx={{mb: 1}}>
                        {data.title}
                    </Typography>
                </Box>
                <Box>
                    <Box>
                        { data.cost ? <Typography variant="button">{data.cost} ₽</Typography> : <Typography variant="button" color='error'>Нет себестоимости</Typography> }
                    </Box>
                    <Box>
                        <Typography variant="caption">Pricer</Typography> { data.pricers.length > 0 ? data.pricers.map((chip, i) =><Chip sx={{mr: 1}} key={i} label={chip} size="small" color="secondary"/>) : null }
                    </Box>
                </Box>
            </Paper>
        )
    }

    const ProductsGrid = () => {
        return (
            <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                {list.map(item => <Item key={item.id} data={item} />)}
            </Box>
        )
    }

    const ViewList = () => {
        if (view === 'grid') {
            return (
                <ProductsGrid />
            )
        } else {
            return (
                <ProductsTable />
            )
        }
    }

    const ViewSelector = () => {
        return (
            <Box sx={{mb:2}}>
                <IconButton 
                    aria-label="list" 
                    color={view === 'list' ? "primary" : ""}
                    onClick={() => setView('list')}>
                    <ListIcon />
                </IconButton>
                <IconButton 
                    aria-label="full-list" 
                    color={view === 'full-list' ? "primary" : ""}
                    onClick={() => setView('full-list')}>
                    <FormatListBulletedIcon />
                </IconButton>
                <IconButton 
                    aria-label="grid" 
                    color={view === 'grid' ? "primary" : ""}
                    onClick={() => setView('grid')}>
                    <GridViewIcon />
                </IconButton>
            </Box>
        )
    }

    return (
        <>
            <ViewSelector />
            {list ? <ViewList /> : <Preloader />}
        </>
    )
}

export default ProductsList