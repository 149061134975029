import { Box, Chip, Divider, Paper, Tooltip, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import Preloader from "../../../../components/Preloader"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { getCountSales } from "../../../../store/shops/counts/countSales"

const CountSales = () => {
    
    const {code} = useParams()

    return (
        <Paper sx={{py: 2, px: 3, mb: 3}} elevation={0}>
            <Typography variant="h6">
                Продажи
            </Typography>
            <Period label={'Сегодня'} period={'today'} code={code} />
            <Divider />
            <Period label={'Вчера'} period={'yesterday'} code={code} />
            <Divider />
            <Period label={'С начала недели'} period={'week'} code={code} />
        </Paper>
    )
}

export default CountSales

const Period = ({label, period, code}) => {

    
    const dispatch = useDispatch()

    const count = useSelector((state) => state.countSales[code])

    useEffect(() => {
        dispatch(getCountSales(code, period))
    }, [dispatch, code, period])

    return (
        <Box sx={{my: 1}}>
            <Typography variant="button">{label}</Typography>
            <Box sx={{position: 'relative', minHeight: 80}}>
                {count && count[period] ? <Count period={count[period]}/> : <Preloader />}
            </Box>
        </Box>
    )
}

const Count = ({period}) => {

    const navigate = useNavigate()

    const handlerClick = () => {
        if (period.count > 0) {
            navigate(`sales?f=${period.from}&t=${period.to}`)
        }
    }

    return (
        <Box sx={{cursor : period.count > 0 ? 'pointer' : 'default'}} onClick={handlerClick}>
            <Typography variant="h5" fontWeight={600} sx={{lineHeight: 1.2}} >
                {period.count}
            </Typography>
            <Typography variant="overline" fontWeight={600} sx={{lineHeight: 1.2}}>
                На сумму
            </Typography>
            <Typography variant="h5" fontWeight={600} sx={{lineHeight: 1.2}}>
                {period.pay.toLocaleString('ru-RU')}
            </Typography>
            <Box sx={{mt: 1, mb: 2}}>
                {period.margin && period.margin > 0 ? <Tooltip title="Маржа"><Chip label={period.margin.toLocaleString('ru-RU')} size="small" sx={{mr: 1}} /></Tooltip> : null }
                {period.percent && period.percent > 0 ? <Tooltip title="Маржинальность"><Chip label={`${period.percent.toLocaleString('ru-RU')} %`} size="small" sx={{mr: 1}} /></Tooltip> : null }
            </Box>
        </Box> 
    )
}