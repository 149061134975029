import { Box, Button, Paper, Typography } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getProductsCount, setCode } from "../../../../store/settings/products"
import Preloader from "../../../../components/Preloader"
import { useNavigate } from "react-router-dom"

const PageCounter = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const count = useSelector((state) => state.settingsProducts.count)

    useEffect(() => {
        dispatch(getProductsCount())
    }, [dispatch])

    const handlerLoadingUnsorted = (code) => {
        dispatch(setCode(code))
        navigate('linker')
    }

    const ShopCount = ({title, count, code, unsorted}) => {
        return (
            <Box sx={{marginRight: 4, paddingRight: 4, borderRight: '1px solid #ccc'}}>
                <Typography sx={{mb: 1}} variant="h6">
                    {title} — {count}
                </Typography>
                { unsorted > 0 ? <Button onClick={() => handlerLoadingUnsorted(code)} variant="outlined">Настроить</Button> : null }
            </Box>
        )
    }

    if (!count) return <Preloader />

    return (
        <Paper
            sx={{p: 3, position: 'relative', display: 'flex', alignItems: 'center'}}
            elevation={0} >
            <Box sx={{marginRight: 4, paddingRight: 4, borderRight: '1px solid #ccc'}}>
                <Typography variant="h1" fontWeight={600}>
                    {count.count}
                </Typography>
            </Box>
            <Box sx={{display: 'flex'}}>
                <ShopCount title="Wildberries" count={count.wbCount} code='wb' unsorted={count.wbUnsorted} />
                <ShopCount title="Ozon" count={count.ozCount} code='oz' unsorted={count.ozUnsorted} />
                <ShopCount title="Yandex" count={count.ymCount} code='ym' unsorted={count.ymUnsorted} />
            </Box>
        </Paper>
    )
}

export default PageCounter