import { useDispatch, useSelector } from "react-redux"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import Preloader from "../../../../components/Preloader"
import LinkIcon from '@mui/icons-material/Link';
import { useEffect, useState } from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { saveProduct, setSaveLink, setSelected } from "../../../../store/settings/products";

const UnsortedList = () => {

    const dispatch = useDispatch()

    const unsorted = useSelector((state) => state.settingsProducts.unsorted)
    const selected = useSelector((state) => state.settingsProducts.selected)
    const list = useSelector((state) => state.settingsProducts.list)
    const code = useSelector((state) => state.settingsProducts.code)
    const saveLink = useSelector((state) => state.settingsProducts.saveLink)

    const [select, setSelect] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [cost, setCost] = useState("")

    const handlerClick = (id) => {
        if (select === id) {
            setSelect(null)
            dispatch(setSelected(null))
        } else {
            setSelect(id)
        }
    }

    const setProductData = () => {
        if (selected) {
            const findIndex = list.findIndex(item => item.id === selected)

            if (findIndex > -1) {
                const cost = list[findIndex].cost

                if (cost) {
                    setCost(cost)
                }
            }
        }
    }

    const handlerConfirm = () => {
        setConfirm(true)
        setProductData()
    }

    const handleClose = () => {
        setCost("")
        setConfirm(false)
    };

    const LinkButtons = () => {
        return (
            <Box sx={{position: 'fixed', p: 2, bottom: 0, left: 0, width: '100%', height: 25, textAlign: 'center', backgroundColor: '#fff', boxShadow: '0 0 3px 3px rgba(0, 0, 0, 0.1)'}}>
                {!selected ?
                <Box sx={{position: 'absolute', left: '45%', bottom: 10}}>
                    <ArrowUpwardIcon />
                    <Typography variant="button" sx={{mr: 2}}>Выберите соответствие или</Typography>
                    <Button variant="contained" onClick={handlerConfirm}>Добавить как новый</Button>
                </Box> :
                <Box sx={{position: 'absolute', left: '50%', width: 300, marginLeft: '-160px', bottom: 10}}>
                    <Button variant="contained" onClick={handlerConfirm}>Связать</Button>
                </Box> }
            </Box>
        )
    }

    const LinkItem = ({id}) => {
        return (
            <Box sx={{position: 'relative'}}>
                {select !== id ? 
                    <CheckBoxOutlineBlankIcon 
                        sx={{cursor: 'pointer'}}
                        onClick={() => handlerClick(id)} fontSize="small"/> : 
                    <CheckBoxIcon 
                        sx={{cursor: 'pointer'}}
                        onClick={() => handlerClick(id)} fontSize="small"/> }
            </Box>
        )
    }

    const Row = ({data}) => {
        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                    <LinkItem id={data.id} />
                </TableCell>
                <TableCell sx={{verticalAlign: 'top'}}>
                    <Typography>
                        {data.title}
                    </Typography>
                </TableCell>
            </TableRow>
        )
    }

    const ProductsTable = () => {

        if (unsorted.length === 0) {
            return (
                <TableContainer component={Paper} elevation={0} sx={{position: 'relative'}}>
                    <Typography sx={{m:3}}>Все товары отсортированы</Typography>
                </TableContainer>
            )
        }

        return (
            <TableContainer component={Paper} elevation={0} sx={{position: 'relative', minHeight: 100}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <LinkIcon fontSize="small" />
                            </TableCell>
                            <TableCell>Название</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {unsorted.map((row, i) => <Row key={i} data={row} />)}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const handlerSubmit = () => {
        dispatch(setSaveLink("start"))
        dispatch(saveProduct(selected, select, cost, code))
    }

    useEffect(() => {
        if (saveLink === 'stop') {
            setSelect(null)
            dispatch(setSelected(null))
            dispatch(setSaveLink(null))
            handleClose()
        }
    }, [saveLink, dispatch])

    return (
        <>
            { unsorted ? <ProductsTable /> : <Preloader /> }
            { select ? <LinkButtons /> : null }
            { confirm ?
                    <Dialog open={confirm} onClose={handleClose}>
                        <DialogTitle>{select && selected ? "Связка с товаром" : "Добавление нового товара" }</DialogTitle>
                        {saveLink === "start" ? <Preloader /> :
                        <>
                            <DialogContent>
                                <DialogContentText sx={{mb: 2}}>
                                    Важно!!! Правильно связать или добавить новые товары для дальнейшей аналитики
                                </DialogContentText>

                                <TextField
                                    autoComplete='off'
                                    margin="dense"
                                    id="cost"
                                    label="Себестоимость"
                                    type="number"
                                    fullWidth
                                    value={cost}
                                    onChange={e => setCost(e.target.value)}
                                    variant="outlined"
                                    />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Отмена</Button>
                                <Button disabled={cost === ""} onClick={handlerSubmit}>Сохранить</Button>
                            </DialogActions>
                        </> }
                    </Dialog> : null }
        </>
    )
}

export default UnsortedList