import { Box, Paper, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { baseURL } from "../../../services/base"

const Images = ({id}) => {

    const product = useSelector((state) => state.settingsProduct.data)

    return (
        <Paper sx={{p:3}} elevation={0}>
            <Typography variant="h6" sx={{mb:3}}>Фото</Typography>
            <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                {product.images.map((el, i) => <Box sx={{flex: '0 0 150px', margin: '0 20px 20px 0'}} key={i}><img style={{width: '100%'}} src={`${baseURL}${el.image}`} alt={product.title} /></Box>)}
            </Box>
        </Paper>
    )
}

export default Images