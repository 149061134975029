import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import Auth from "./components/Auth";
import Error from "./screen/Error";
import Register from "./screen/Register";
import Home from "./screen/Home";
import Shop from "./screen/Shop";
import StartPage from "./screen/Shop/StartPage";
import Sales from "./screen/Shop/Sales";
import Settings from "./screen/Settings";
import SellerProductsPage from "./screen/Settings/SellerProducts/SellerProductsPage";
import Linker from "./screen/Settings/SellerProducts/Linker";
import SellerProduct from "./screen/Settings/SellerProduct";
import ShopProducts from "./screen/Shop/Products";
import ShopProduct from "./screen/Shop/Product";
import ResetPassword from "./screen/ResetPassword";
import NewPassword from "./screen/ResetPassword/NewPassword";

const Layout = () => {
    return (
        <Auth>
            <Outlet />
        </Auth>
    )
}

const router = createBrowserRouter([
        {
            element: <Layout />,
            errorElement: <Error />,
            children: [
                {
                    path: '/',
                    element: <Home />
                },
                {
                    path: 'shops',
                    element: <Home />
                },
                {
                    path: 'shops/:code',
                    element: <Shop />,
                    children: [
                        {
                            path: '',
                            element: <StartPage />
                        },
                        {
                            path: 'sales',
                            element: <Sales />
                        },
                        {
                            path: 'products',
                            element: <ShopProducts />
                        },
                        {
                            path: 'products/:id',
                            element: <ShopProduct />
                        }
                    ]
                },
                {
                    path: 'settings',
                    element: <Settings />
                },
                {
                    path: 'settings/products',
                    element: <SellerProductsPage />
                },
                {
                    path: 'settings/products/linker',
                    element: <Linker />
                },
                {
                    path: 'settings/product/:id',
                    element: <SellerProduct />
                }
            ]
        },
        {
            path: '/register',
            errorElement: <Error />,
            element: <Register />
        },
        {
            path: '/reset-password',
            errorElement: <Error />,
            element: <ResetPassword />
        },
        {
            path: '/new-password',
            errorElement: <Error />,
            element: <NewPassword />
        }
    ]);

const App = () => {
    return (
        <RouterProvider router={router} />
    );
}

export default App;