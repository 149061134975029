import { Grid } from "@mui/material"
import CountProducts from "./CountProducts"
import CountSales from "./CountSales"
import { useParams } from "react-router-dom"
import PeriodGraph from "./GraphMain/PeriodGraph"
import WeekGarph from "./GraphMain/WeekGraph"
import GraphicTypeSwitcher from "../../../components/GraphTypeSwitcher"

const StartPage = () => {

    const {code} = useParams()

    return (
        <Grid container spacing={4}>
            <Grid item xs={2}>
                <CountProducts code={code} />
                <CountSales />
            </Grid>
            <Grid item xs={10}>
                <GraphicTypeSwitcher />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <PeriodGraph code={code} />
                    </Grid>
                    <Grid item xs={12}>
                        <WeekGarph code={code} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StartPage