import { createSlice } from '@reduxjs/toolkit'
import { apiGetRequest } from '../../../services/base';

export const graphicHomeWeek = createSlice({
    name: 'graphicHomeWeek',
    initialState: {
        data: null
    },
    reducers: {
        setGraphHome: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setGraphHome } = graphicHomeWeek.actions

export const getGraphHomeWeek = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/week`,
        callBack: setGraphHome
    }

    dispatch(apiGetRequest(params))
}


export default graphicHomeWeek.reducer