import { Grid } from "@mui/material"

import PageTitle from "../../components/Pages/PageTitle"
import Margin from "./Margin"
import Nalog from "./Nalog"
import SellerProductsCount from "./SellerProducts/SellerProductsCount"

const Settings = () => {
    return (
        <>
            <PageTitle title="Настройки" />
            <Grid container spacing={4}>
                <Grid item xs={2}>
                    <Margin />
                </Grid>
                <Grid item xs={3}>
                    <Nalog />
                </Grid>
                <Grid item xs={2}>
                    <SellerProductsCount />
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>
        </>
    )
}

export default Settings