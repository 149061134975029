import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiGetRequest } from '../../services/base';
axios.defaults.withCredentials = true;

export const shopYM = createSlice({
    name: 'shopYM',
    initialState: {
        shop: null,
        count: null,
        today: null,
        yesterday: null,
        week: null,
        graph: null,
        graphWeek: null,
        graphWeekVals: null,
        lastUpdate: null
    },
    reducers: {
        setShopYM: (state, action) => {
            state.shop = action.payload
        },
        setCountYM: (state, action) => {
            state.count = action.payload
        },
        setTodayYM: (state, action) => {
            state.today = action.payload
        },
        setYesterdayYM: (state, action) => {
            state.yesterday = action.payload
        },
        setWeekYM: (state, action) => {
            state.week = action.payload
        },
        setGraphYM: (state, action) => {
            state.graph = action.payload
        },
        setGraphWeekYM: (state, action) => {
            state.graphWeek = action.payload
        },
        setGraphWeekValsYM: (state, action) => {
            state.graphWeekVals = action.payload
        },
        setLastUpdateYM: (state, action) => {
            state.lastUpdate = action.payload
        },
    }
})

export const { setShopYM, setCountYM, setTodayYM, setYesterdayYM, setWeekYM, setGraphYM, setGraphWeekYM, setGraphWeekValsYM, setLastUpdateYM } = shopYM.actions

export const getShopYM = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/ym`,
        callBack: setShopYM
    }

    dispatch(apiGetRequest(params))

    let date = new Date()
    dispatch(setLastUpdateYM(date.getTime()))
}

export const getCountYM = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/ym/products?flag=count`,
        callBack: setCountYM
    }

    dispatch(apiGetRequest(params))
}

export const setPeriodYM = (data) => (dispatch, getState) => {
    switch(data.period) {
        case 'today':
            dispatch(setTodayYM(data))
            break;
        case 'yesterday':
            dispatch(setYesterdayYM(data))
            break;
        case 'week':
            dispatch(setWeekYM(data))
            break;
        default:
            //console.log(data.period)
    }
}

export const getPeriodYM = (period) => (dispatch, getState) => {
    const params = {
        url: `/api/shops/ym/statistic/count?period=${period}`,
        callBack: setPeriodYM
    }

    dispatch(apiGetRequest(params))
}

export const getGraphYM = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/ym/statistic/graph`,
        callBack: setGraphYM
    }

    dispatch(apiGetRequest(params))
}

export const getGraphWeekYM = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/ym/statistic/week`,
        callBack: setGraphWeekYM
    }

    dispatch(apiGetRequest(params))
}

export default shopYM.reducer