import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Paper, TextField, Typography } from "@mui/material";
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { getMargin, saveMargin } from "../../../store/settings/margin";
import Preloader from "../../../components/Preloader";

const FormField = ({label, k, value}) => {

    const dispatch = useDispatch();

    const [val, setVal] = useState("")
    const [submit, setSubmit] = useState(false)

    useEffect(() => {
        if (value) {
            setVal(value)
        }
    }, [value])

    const handlerChange = (changeVal) => {
        setVal(changeVal)

        if (parseInt(changeVal) !== value) {
            setSubmit(true)
        } else {
            setSubmit(false)
        }
    }

    const handlerSubmit = () => {
        dispatch(saveMargin(k, val))
    }

    return (
        <Box display='flex' sx={{mb: 2, alignItems: 'flex-end'}}>
            <TextField 
                id={`margin-${k}`}
                label={label}
                variant="standard"
                type="number"
                onChange={e => handlerChange(e.target.value)}
                value={val} />
            {submit ? <IconButton aria-label="Сохранить" color="primary" onClick={handlerSubmit}><ChangeCircleOutlinedIcon /></IconButton> : null}
        </Box>
    )
}

const Margin = () => {

    const dispatch = useDispatch();
    const margin = useSelector((state) => state.margin)

    useEffect(() => {
        dispatch(getMargin())
    }, [dispatch])

    const Form = () => {
        const keys = [
            {
                key: 'max',
                label: 'Оптимальная'
            },
            {
                key: 'mid',
                label: 'Удовлетворительная'
            },
            {
                key: 'min',
                label: 'Критическая'
            }
        ]
    
        return (
            <>
                {keys.map(item => <FormField label={item.label} key={item.key} k={item.key} value={margin[item.key]} />)}
            </>    
        )
    }

    return (
        <Paper sx={{p: 3, position: 'relative', minHeight: 250}} elevation={0}>
            <Typography variant="h4" sx={{mb: 2}}>
                Маржа
            </Typography>
            {margin.min !== null ? <Form /> : <Preloader />}
        </Paper>
    )
}

export default Margin