import { createSlice } from '@reduxjs/toolkit'
import { apiGetRequest } from '../../../services/base';

export const countProducts = createSlice({
    name: 'countProducts',
    initialState: {
        wb: null,
        oz: null
    },
    reducers: {
        setCountProductsWB: (state, action) => {
            state.wb = action.payload
        },
        setCountProductsOZ: (state, action) => {
            state.oz = action.payload
        }
    }
})

export const { setCountProductsOZ, setCountProductsWB } = countProducts.actions

const setCountProducts = (data) => (dispatch, getState) => {
    
    if (data.shopCode === 'wb') {
        dispatch(setCountProductsWB(data.count))
    } else if (data.shopCode === 'oz') {
        dispatch(setCountProductsOZ(data.count))
    } else {
        //console.log(data)
    }
}

export const getCountProducts = (code) => (dispatch, getState) => {
    const params = {
        url: `/api/shops/${code}/products?flag=count`,
        callBack: setCountProducts
    }

    dispatch(apiGetRequest(params))
}


export default countProducts.reducer