import { Box, Button } from "@mui/material"
import { useState } from "react"

const DatePicker = ({from, to, submit}) => {
    
    const [f, setF] = useState(from)
    const [t, setT] = useState(to)

    const handleClick = () => {
        submit({f, t})
    }

    return (
        <Box>
            <input 
                className="datepicker" 
                onChange={e => setF(e.target.value)}
                type="date" 
                value={f} /> 
                — 
                <input 
                className="datepicker" 
                onChange={e => setT(e.target.value)}
                min={f}
                type="date" 
                value={t} />
            <Button onClick={handleClick} sx={{ml: 2}}>Обновить</Button>
        </Box>
    )
}

export default DatePicker