import { Outlet, useParams } from "react-router-dom"
import ShopWB from "./ShopWB"
import Preloader from "../../components/Preloader"
import ShopOZ from "./ShopOZ"
import ShopYM from "./ShopYM"

const Shop = () => {

    const {code} = useParams()

    if (code === 'wb') {
        return (<ShopWB outlet={<Outlet/>} />)
    }

    if (code === 'oz') {
        return (<ShopOZ outlet={<Outlet/>} />)
    }

    if (code === 'ym') {
        return (<ShopYM outlet={<Outlet/>} />)
    }

    return (
        <Preloader />
    )
}

export default Shop