import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiGetRequest } from '../../services/base';
axios.defaults.withCredentials = true;

export const shopProducts = createSlice({
    name: 'shopProducts',
    initialState: {
        items: null,
        product: null,
        productSales: null,
        productSaleData: null
    },
    reducers: {
        setProducts: (state, action) => {
            state.items = action.payload
        },
        setProduct: (state, action) => {
            state.product = action.payload
        },
        setProductSales: (state, action) => {
            state.productSales = action.payload
        },
        setProductSaleData: (state, action) => {
            state.productSaleData = action.payload
        }
    }
})

export const { setProducts, setProduct, setProductSales, setProductSaleData } = shopProducts.actions

export const getProducts = (code) => (dispatch, getState) => {

    const params = {
        url: `/api/shops/${code}/products?flag=data`,
        callBack: setProducts
    }

    dispatch(apiGetRequest(params))
}

export const getProduct = (code, id) => (dispatch, getState) => {

    const params = {
        url: `/api/shops/${code}/products/${id}`,
        callBack: setProduct
    }

    dispatch(apiGetRequest(params))
}

export const getProductSales = (code, id, f = null, t = null) => (dispatch, getState) => {

    const params = {
        url: `/api/shops/${code}/products/${id}/sales?from=${f}&to=${t}`,
        callBack: setProductSales
    }

    dispatch(apiGetRequest(params))
}

export const getProductSale = (code, id) => (dispatch, getState) => {

    const params = {
        url: `/api/shops/${code}/sales/${id}`,
        callBack: setProductSaleData
    }

    dispatch(apiGetRequest(params))
}

export default shopProducts.reducer