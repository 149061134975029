import { useState } from "react"
import { useDispatch } from "react-redux";
import Wrapper from "../../components/Wrapper";
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { setNewPassword } from "../../store/auth";

const NewPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [name, setName] = useState("")
    const [_name, _setName] = useState(false)
    const [email, setEmail] = useState("")
    const [_email, _setEmail] = useState(false)
    const [password, setPassword] = useState("")
    const [_password, _setPassword] = useState(false)
    const [passwordConf, setPasswordConf] = useState("")
    const [_passwordConf, _setPasswordConf] = useState(false)

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConf, setShowPasswordConf] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPasswordConf = () => setShowPasswordConf((showConf) => !showConf);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const handleMouseDownPasswordConf = (event) => {
        event.preventDefault();
    };

    const handlerInputName = (value) => {
        value = value.trim()
        setName(value)
        _setName(false)
    }

    const handlerInputEmail = (value) => {
        value = value.trim()
        setEmail(value)
        _setEmail(false)
    }

    const handlerInputPassword = (value) => {
        value = value.trim()
        setPassword(value)
        _setPassword(false)
    }

    const handlerInputPasswordConf = (value) => {
        value = value.trim()
        setPasswordConf(value)
        _setPasswordConf(false)
    }

    const validateEmail = (email) => {
        const emailRegexp = new RegExp(
            //eslint-disable-next-line
            /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i
        )
        
        return emailRegexp.test(email)
    };

    const handlerSubmit = () => {
        if (name.length === 0) {
            _setName(true)
        } else if (email.length === 0) {
            _setEmail(true)
        } else if (validateEmail(email) === false) {
            _setEmail(true)
        } else if (password.length < 7) {
            _setPassword(true)
        } else if (password !== passwordConf) {
            _setPasswordConf(true)
        } else {
            dispatch(setNewPassword(name, email, password, passwordConf, navigate))
        }
    }

    return (
        <Wrapper header={false}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                style={{ minHeight: '100vh' }}>
                <Grid item alignSelf='end'>
                    <Box sx={{pr : 3, pt : 2}}>
                        <Typography>
                            <Link onClick={() => navigate('/')} sx={{cursor: 'pointer'}}>Войти</Link>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="h4" fontWeight={600} textAlign='center' sx={{mb: 3}}>
                        Новый пароль
                    </Typography>
                    <Box>
                        <FormControl sx={{ mb: 3, width: '45ch' }} variant="outlined">
                            <InputLabel htmlFor="name">Код из письма</InputLabel>
                            <OutlinedInput
                                id="name"
                                type='text'
                                value={name}
                                error={_name}
                                onChange={e => handlerInputName(e.target.value)}
                                label="Пароль"
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl sx={{ mb: 3, width: '45ch' }} variant="outlined">
                            <InputLabel htmlFor="email">Email</InputLabel>
                            <OutlinedInput
                                id="email"
                                type='text'
                                value={email}
                                error={_email}
                                onChange={e => handlerInputEmail(e.target.value)}
                                label="Email"
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl sx={{ mb: 1, width: '45ch' }} variant="outlined">
                            <InputLabel htmlFor="assword">Пароль</InputLabel>
                            <OutlinedInput
                            id="password"
                            value={password}
                            error={_password}
                            onChange={e => handlerInputPassword(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Пароль"
                            />
                            <Typography color='#777777' variant='caption'>Не менее 7 символов</Typography>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl sx={{ mb: 2, width: '45ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Подтверждение пароля</InputLabel>
                            <OutlinedInput
                            id="outlined-adornment-password"
                            value={passwordConf}
                            error={_passwordConf}
                            onChange={e => handlerInputPasswordConf(e.target.value)}
                            type={showPasswordConf ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordConf}
                                    onMouseDown={handleMouseDownPasswordConf}
                                    edge="end"
                                    >
                                    {showPasswordConf ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Подтверждение пароля"
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <Button onClick={handlerSubmit} variant="contained">Сохранить</Button>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{pb : 2}}>
                        <Typography>
                            <Link sx={{cursor: 'pointer'}}>На сайт</Link>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Wrapper>
    )
}

export default NewPassword