import { Box, Button, Paper, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { bidService } from "../../../../store/services/services"
import PricerForm from "./PricerForm"
import PricerLog from "./PricerLog"
import { useEffect } from "react"
import { setMaxPercent, setMinPercent, setTarget, setOzPricer } from "../../../../store/services/pricerOz"

const PricerOZ = ({productID}) => { 

    const pricer = useSelector((state) => state.services.services.PricerOZ)
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(setTarget(null))
            dispatch(setMinPercent(null))
            dispatch(setMaxPercent(null))
            dispatch(setOzPricer(null))
        }
    }, [dispatch])

    return (
        <Paper sx={{p:3, mb: 4}} elevation={0}>
            <Typography variant="h6" sx={{mb:3}}>Регулятор цен OZON</Typography>
            {pricer.access ? <><PricerForm productID={productID} /><PricerLog productID={productID} /></> : <NoAccess />}
        </Paper>
    )
}

export default PricerOZ

const NoAccess = () => {
    
    const dispatch = useDispatch()

    const pricer = useSelector((state) => state.services.services.PricerOZ)

    const bidLabel = () => {
        switch (pricer.bid) {
            case 0:
                return "Подключение"
            case 1:
                return "Оплата"
            default:
                return "Свяжитесь с менеджером"
        }
    }

    return (
        <Box>
            <Typography mb={3}>Позволяет автоматически регулировать цены на маркетплейсах</Typography>
            {pricer.bid === null ? <Button onClick={() => dispatch(bidService(pricer.id))} variant="contained">Подключить</Button> : <Typography variant="button">{bidLabel()}</Typography>}
        </Box>
    )
}