import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiGetRequest } from '../../services/base';
axios.defaults.withCredentials = true;

export const shopWB = createSlice({
    name: 'shopWB',
    initialState: {
        shop: null,
        count: null,
        today: null,
        yesterday: null,
        week: null,
        graphWeek: null,
        graphWeekVals: null,
        lastUpdate: null
    },
    reducers: {
        setShopWB: (state, action) => {
            state.shop = action.payload
        },
        setCountWB: (state, action) => {
            state.count = action.payload
        },
        setTodayWB: (state, action) => {
            state.today = action.payload
        },
        setYesterdayWB: (state, action) => {
            state.yesterday = action.payload
        },
        setWeekWB: (state, action) => {
            state.week = action.payload
        },
        setGraphWeekWB: (state, action) => {
            state.graphWeek = action.payload
        },
        setGraphWeekValsWB: (state, action) => {
            state.graphWeekVals = action.payload
        },
        setLastUpdateWB: (state, action) => {
            state.lastUpdate = action.payload
        },
    }
})

export const { setShopWB, setCountWB, setTodayWB, setYesterdayWB, setWeekWB, setGraphWeekWB, setGraphWeekValsWB, setLastUpdateWB } = shopWB.actions

export const getShopWB = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/wb`,
        callBack: setShopWB
    }

    dispatch(apiGetRequest(params))

    let date = new Date()
    dispatch(setLastUpdateWB(date.getTime()))
}

export const getCountWB = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/wb/products?flag=count`,
        callBack: setCountWB
    }

    dispatch(apiGetRequest(params))
}

export const setPeriodWB = (data) => (dispatch, getState) => {
    switch(data.period) {
        case 'today':
            dispatch(setTodayWB(data))
            break;
        case 'yesterday':
            dispatch(setYesterdayWB(data))
            break;
        case 'week':
            dispatch(setWeekWB(data))
            break;
        default:
            //console.log(data.period)
    }
}

export const getPeriodWB = (period) => (dispatch, getState) => {
    const params = {
        url: `/api/shops/wb/statistic/count?period=${period}`,
        callBack: setPeriodWB
    }

    dispatch(apiGetRequest(params))
}

export const getGraphWeekWB = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/wb/statistic/week`,
        callBack: setGraphWeekWB
    }

    dispatch(apiGetRequest(params))
}

export default shopWB.reducer