import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import Preloader from "../../components/Preloader";
import { getShops } from "../../store/shops/shops";
import HomeShop from "./HomeShop";
import HomeGraph from "./HomeGraph";
import HomePie from "./HomePie";
import HomeNew from "./HomeNew";
import HomeWeek from "./HomeWeek";
import GraphicTypeSwitcher from "../../components/GraphTypeSwitcher";

const Home = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const shops = useSelector((state) => state.shops.shops)

    useEffect(() => {
        dispatch(getShops())
    }, [dispatch])

    return (
        <Box display='flex' sx={{ flexDirection: 'column', justifyContent: 'center', minHeight: 600 }}>
            <Grid container spacing={4} sx={{mb:2}}>
                <Grid item xs={2}>
                    <HomeNew />
                </Grid>
                <Grid item xs={8}>
                    <GraphicTypeSwitcher home={true} />
                    <HomeGraph />
                    <HomeWeek />
                </Grid>
                <Grid item xs={2}>
                    <Box sx={{position: 'relative'}}>
                        <HomePie />
                    </Box>
                </Grid>
            </Grid>
                
            {shops.length > 0 ?
            <Box display='flex' sx={{ justifyContent: 'center' }}>
                {shops.map((item, i) => <HomeShop key={i} shop={item} />)}
            </Box> : <Preloader /> }
            <Box display='flex' sx={{ justifyContent: 'center' }}>
                <Button onClick={() => navigate('/settings')}>Настройки</Button>
            </Box>
        </Box>
    )
}

export default Home