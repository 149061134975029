import { AppBar, Box, Dialog, IconButton, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { red, amber, lime, lightGreen } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import Preloader from "../../../components/Preloader";
import { useParams } from "react-router-dom";
import { getMargin } from "../../../store/settings/margin";
import { setProductSaleData } from "../../../store/shops/shopProducts";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ProductSales = () => {

    const {code} = useParams()
    
    const productSales = useSelector((state) => state.shopProducts.productSales)
    const margin = useSelector((state) => state.margin)

    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)
    const [sale, setSale] = useState(null)

    const handleOpen = (data) => {
/*         setSale(data)
        setOpen(true)
        dispatch(getProductSale(code, data.srid)) */
    };

    const handleClose = () => {
        setOpen(false)
        setSale(null)
        dispatch(setProductSaleData(null))
    };

    useEffect(() => {
        if (!margin) {
            dispatch(getMargin())
        }
    }, [dispatch, margin])

    const getColorMargin = (value) => {
        if (margin.min === 0 && margin.mid === 0 && margin.max === 0) {
            return '#fff'
        } else {
            if (value < margin.min) {
                return red[500];
            } else if (value < margin.mid) {
                return amber[500];
            } else if (value < margin.max) {
                return lime[500];
            } else {
                return lightGreen[500]
            }
        }
    }

    const Row = ({data}) => {

        const [prognoz, setPrognoz] = useState('#fff')
        const [report, setReport] = useState('#fff')

        useEffect(() => {
            setPrognoz(getColorMargin(data.prognozPercent))
            setReport(getColorMargin(data.reportPercent))
        }, [data.prognozPercent, data.reportPercent])

        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell onClick={() => handleOpen(data)} sx={{cursor: 'pointer'}}>
                    {data.date}
                </TableCell>
                <TableCell>{data.saleRealisation}</TableCell>
                {code === 'wb' ? <>
                    <TableCell>{data.saleForPay}</TableCell>
                    <TableCell sx={{backgroundColor: prognoz}}>{data.prognozMargin}</TableCell>
                    <TableCell sx={{backgroundColor: prognoz}}>{data.prognozPercent} %</TableCell></> : null}

                {
                    data.reportPay === 0 && data.reportDelivery === 0 && data.reportMargin === 0 && data.reportPercent === 0 ?
                    <TableCell colSpan={4} sx={{textAlign: 'center'}}>Ждем отчет магазина</TableCell> :
                    <>
                        <TableCell>{data.reportPay}</TableCell>
                        {code === 'wb' ? <TableCell>{data.reportDelivery}</TableCell> : null}

                        
                        <TableCell sx={{backgroundColor: report}}>{data.reportMargin}</TableCell>
                        <TableCell sx={{backgroundColor: report}}>{data.reportPercent} %</TableCell>
                    </>
                }
            </TableRow>
        )
    }

    return (
        <>
            <TableContainer component={Paper} sx={{mt: 4}} elevation={0}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}></TableCell>
                            {code === 'wb' ? <TableCell>До уточнения</TableCell> : null}
                            {code === 'wb' ? <TableCell colSpan={2}>Прогноз</TableCell> : null}
                            <TableCell colSpan={4}>Итог</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Дата и время</TableCell>
                            <TableCell>Реализация</TableCell>
                            <TableCell>К выплате</TableCell>
                            <TableCell>Маржа</TableCell>
                            <TableCell>Маржинальность</TableCell>
                            
                            {code === 'wb' ? <TableCell>Выплата</TableCell> : null}
                            {code === 'wb' ? <TableCell>Логистика</TableCell> : null}
                            {code === 'wb' ? <TableCell>Маржа</TableCell> : null}
                            {code === 'wb' ? <TableCell>Маржинальность</TableCell> : null}
                            
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productSales.sales.map((row, i) => <Row key={i} data={row} />)}
                    </TableBody>
                </Table>
            </TableContainer>
            {open && sale ? 
                <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {sale.product.title}
                        </Typography>
                    </Toolbar>
                    </AppBar>
                    <Box sx={{p:4}}>
                        <Box sx={{mb: 2}}>ID записи (srid) — {sale.srid}</Box>
                        <SaleData />
                    </Box>
                </Dialog> : null }
        </>
    )
}

export default ProductSales

const SaleData = () => {
    
    const saleData = useSelector((state) => state.shopProducts.productSaleData)

    if (!saleData) {
        return (
            <Preloader />
        )
    }

    //console.log(saleData)

    return (
        <Box>
            <Typography variant="h4">Данные о продаже</Typography>
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell>Дата</TableCell>
                        <TableCell>{saleData.sale.date}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>ID продажи</TableCell>
                        <TableCell>{saleData.sale.sale_id}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Цена до согласованной скидки</TableCell>
                        <TableCell>{saleData.sale.total_price}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Согласованный итоговый дисконт</TableCell>
                        <TableCell>{saleData.sale.discount_percent}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Фактическая цена заказа с учетом всех скидок</TableCell>
                        <TableCell>{saleData.sale.finished_price}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>К перечислению продавцу</TableCell>
                        <TableCell>{saleData.sale.for_pay}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Склад</TableCell>
                        <TableCell>{saleData.sale.warehouse}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Страна</TableCell>
                        <TableCell>{saleData.sale.country}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Регион / Область</TableCell>
                        <TableCell>{saleData.sale.region} {saleData.sale.oblast}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            {saleData.report ? <ReportData /> : null}
        </Box>
    )
}

const ReportData = () => {

    const saleData = useSelector((state) => state.shopProducts.productSaleData)

    const ReportRow = ({data}) => {
        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                    {data.type}
                </TableCell>
                <TableCell>
                    {data.oper_name}
                </TableCell>
                <TableCell>
                    {data.office}
                </TableCell>
                <TableCell>
                    {data.retail_amount}
                </TableCell>
                <TableCell sx={{backgroundColor: data.sign === '+' ? lime[500] : red[500]}}></TableCell>
                <TableCell>
                    {data.delivery_rub}
                </TableCell>
                <TableCell>
                    {data.ppvz_for_pay}
                </TableCell>
                <TableCell>
                    {data.ppvz_reward}
                </TableCell>
                <TableCell>
                    {data.penalty}
                </TableCell>
                <TableCell>
                    {data.additional_payment}
                </TableCell>
                <TableCell>
                    {data.bonus_type}
                </TableCell>
            </TableRow>
        )
    }

    return (
        <>
            <Typography variant="h5" sx={{mt: 3}}>Данные из отчёта</Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Тип</TableCell>
                            <TableCell>Операция</TableCell>
                            <TableCell>Склад</TableCell>
                            <TableCell>Сумма продаж (возвратов) WB</TableCell>
                            <TableCell></TableCell>
                            <TableCell>Логистика</TableCell>
                            <TableCell>К перечислению</TableCell>
                            <TableCell>Возмещение за выдачу и возврат</TableCell>
                            <TableCell>Штрафы</TableCell>
                            <TableCell>Доплаты</TableCell>
                            <TableCell>Обоснование штрафов и доплат</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {saleData.report.map((item, i) => <ReportRow key={i} data={item} />)}
                    </TableBody>
                </Table>
        </>
    )
}