import { createSlice } from '@reduxjs/toolkit'
import { apiGetRequest, apiPostRequest } from '../../services/base';

export const services = createSlice({
    name: 'services',
    initialState: {
        services: null
    },
    reducers: {
        setServices: (state, action) => {
            state.services = action.payload
        }
    }
})

export const { setServices } = services.actions

export const getServices = () => (dispatch, getState) => {
    const params = {
        url: `/api/services`,
        callBack: setServices
    }

    dispatch(apiGetRequest(params))
}

export const bidService = (service) => (dispatch, getState) => {
    const data = new FormData()
    data.append('service', service)

    const params = {
        url: `/api/services/bid`,
        callBack: getServices,
        data: data
    }

    dispatch(apiPostRequest(params))
}

export default services.reducer