import { createSlice } from '@reduxjs/toolkit'
import { apiGetRequest, apiPostRequest } from '../../services/base';

export const pricerOz = createSlice({
    name: 'pricerOz',
    initialState: {
        target: null,
        minPercent: null,
        maxPercent: null,
        wbData: null,
        prodData: null
    },
    reducers: {
        setTarget: (state, action) => {
            state.target = action.payload
        },
        setMinPercent: (state, action) => {
            state.minPercent = action.payload
        },
        setMaxPercent: (state, action) => {
            state.maxPercent = action.payload
        },
        setOzPricer: (state, action) => {
            state.wbData = action.payload
        },
        setOzData: (state, action) => {
            state.prodData = action.payload
        }
    }
})

export const { setTarget, setOzPricer, setMinPercent, setMaxPercent, setOzData } = pricerOz.actions

export const setValues = (data) => (dispatch, getState) => {
    if (data) {
        //console.log(data);
        if (data.target_price) {
            dispatch(setTarget(data.target_price))
            dispatch(setMinPercent(data.min_percent))
            dispatch(setMaxPercent(data.max_percent))
        }
        dispatch(setOzData(data));
    } else {
        console.log("N")
    }
}

export const setLog = (data) => (dispatch, getState) => {
    dispatch(setOzPricer(data))
}

export const getPricerInfo = (productID) => (dispatch, getState) => {

    dispatch(setTarget(null))
    dispatch(setMinPercent(null))
    dispatch(setMaxPercent(null))
    dispatch(setOzData(null))

    const params = {
        url: `/api/services/pricer/oz/${productID}`,
        callBack: setValues
    }

    dispatch(apiGetRequest(params))
}

export const setTargetPrice = (productID, targetPrice, minPercent, maxPercent, active) => (dispatch, getState) => {
    const data = new FormData()
    data.append('price', targetPrice)
    data.append('minPercent', minPercent)
    data.append('maxPercent', maxPercent)
    data.append('active', active)

    const params = {
        url: `/api/services/pricer/oz/${productID}`,
        callBack: getPricerInfo,
        data: data
    }

    dispatch(apiPostRequest(params))
}

export const getPricerLog = (productID)  => (dispatch, getState) => {
    const params = {
        url: `/api/services/pricer/oz/${productID}/log`,
        callBack: setLog
    }

    dispatch(apiGetRequest(params))
}

export default pricerOz.reducer