import { Box, Grid, Paper, Typography } from "@mui/material"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import ProductSales from "./ProductSales"
import ProductSaleData from "./ProductSaleData"
import Preloader from "../../../components/Preloader"
import DatePicker from "../../../components/DatePicker"
import { getProduct, getProductSales, setProduct, setProductSales } from "../../../store/shops/shopProducts"
import PageTitle from "../../../components/Pages/PageTitle"

const ShopProduct = () => {

    const {code, id} = useParams()

    const product = useSelector((state) => state.shopProducts.product)
    const productSales = useSelector((state) => state.shopProducts.productSales)
    const [searchParams] = useSearchParams();

    const f = searchParams.get('f') !== null ? searchParams.get('f') : ""
    const t = searchParams.get('t') !== null ? searchParams.get('t') : ""

    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProduct(code, id))
    }, [dispatch, code, id])

    useEffect(() => {
        dispatch(getProductSales(code, id, f, t))
    }, [code, id, f, t, dispatch])

    useEffect(() => {
        return () => {
            dispatch(setProduct(null))
            dispatch(setProductSales(null))
        }
    }, [dispatch])

    const Product = () => {
        return (
            <Paper elevation={0} sx={{p:3}}>
                <Box sx={{display: 'flex'}}>
                    <Box sx={{flex: '0 0 200px', mr: 3}}>
                        <img style={{width: '100%'}} src={product.images[0].url} alt={product.title} />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography variant="button">
                                {product.category}
                            </Typography>
                            <Typography variant="h5" fontWeight={600}>
                                {product.article}
                            </Typography>
                            <Typography>
                                {product.brand}
                            </Typography>
                        </Box>
                        
                        {productSales ? <Typography>Последняя продажа — {productSales.lastSale}</Typography> : null}
                    </Box>
                </Box>
            </Paper>
        )
    }

    const datePickerChange = (e) => {
        dispatch(setProductSales(null))
        navigate(`?f=${e.f}&t=${e.t}`)
    }

    if (!product) return <Preloader/>

    return (
        <Box>
            <PageTitle title={product.title} back={`/shops/${code}/products`} size="h5" />
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <Product />
                    {productSales ? <ProductSaleData /> : <Preloader />}
                </Grid>
                <Grid item xs={8}>
                    <DatePicker from={f} to={t} submit={datePickerChange} />
                    {productSales ? <ProductSales /> : <Preloader />}
                </Grid>
            </Grid>
        </Box>
    )
}

export default ShopProduct