import { FormControl, FormControlLabel, Paper, Radio, RadioGroup, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Preloader from "../../../components/Preloader";
import { getNalog, saveNalog } from "../../../store/settings/nalog";

const Nalog = () => {
    
    const dispatch = useDispatch();
    const nalog = useSelector((state) => state.nalog.value)

    const [value, setValue] = useState("")

    useEffect(() => {
        dispatch(getNalog())
    }, [dispatch])

    const handlerChange = (id) => {
        dispatch(saveNalog(id))
    }

    useEffect(() => {
        if (nalog) {
            const index = nalog.findIndex(item => item.itemID !== null)
            
            if (index > -1) {
                setValue(nalog[index].id)
            } else {
                setValue(null)
            }
        }
    }, [nalog])

    const Form = () => {
        return (
            <FormControl>
                    <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={value}
                    >
                        {nalog.map((item, i) => <FormControlLabel key={i} onChange={() => handlerChange(item.id)} value={item.id} control={<Radio />} label={`${item.title} — ${item.value} %`} />)}
                    </RadioGroup>
            </FormControl>
        )
    }

    return (
        <Paper sx={{p: 3, position: 'relative', minHeight: 250, backgroundColor: value === null ? "red" : "#fff"}} elevation={0}>
            <Typography variant="h4" sx={{mb: 2}}>
                Налогообложение
            </Typography>
            {nalog ? <Form /> : <Preloader />}
        </Paper>
    )
}

export default Nalog