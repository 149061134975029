import { createSlice } from '@reduxjs/toolkit'
import { apiGetRequest } from '../../../services/base';

export const countSales = createSlice({
    name: 'countSales',
    initialState: {
        wb: null,
        oz: null
    },
    reducers: {
        setCountSalesWB: (state, action) => {
            state.wb = action.payload
        },
        setCountSalesOZ: (state, action) => {
            state.oz = action.payload
        }
    }
})

export const { setCountSalesOZ, setCountSalesWB } = countSales.actions

const setCountSales = (data) => (dispatch, getState) => {

    let old = getState().countSales[data.shopCode]

    let period = data.period;

    if (old === null) {
        old = {}
        old = {
            [period] : data
        }
    } else {
        old = {...old, ...{[period] : data}}
    }
    
    if (data.shopCode === 'wb') {
        dispatch(setCountSalesWB(old))
    } else if (data.shopCode === 'oz') {
        dispatch(setCountSalesOZ(old))
    } else {
        //console.log(data)
    }
}

export const getCountSales = (code, period) => (dispatch, getState) => {
    const params = {
        url: `/api/shops/${code}/statistic/count?period=${period}`,
        callBack: setCountSales
    }

    dispatch(apiGetRequest(params))
}


export default countSales.reducer