import { Box } from "@mui/material"
import Logo from "./logo"
import User from "./user"

const Header = () => {
    return (
        <Box sx={{
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 3
            }}>
            <Logo />
            <User />
        </Box>
    )
}

export default Header