import { Paper } from "@mui/material"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import Preloader from "../../../../components/Preloader";
import { useDispatch, useSelector } from "react-redux";
import { getGraphPeriod } from "../../../../store/shops/graphics/graphicPeriod";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
);

const PeriodGraph = ({code}) => {

    const dispatch = useDispatch()

    const type = useSelector((state) => state.graphicType.value)
    const graph = useSelector((state) => state.graphicPeriod[code])

    const [labels, setLables] = useState([])
    const [values, setValues] = useState(null)
    const [min, setMin] = useState(0)
    const [max, setMax] = useState(100)

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    maxRotation: 45,
                    minRotation: 45
                }
            }
        },
        plugins: {
            legend: {
                position: "top",
            },
            tooltip: {
                callbacks: {
                    labelTextColor: function(context) {
                        return '#fff';
                    }
                }
            },
            annotation: {
                annotations: {
                    min : {
                        type: 'line',
                        borderColor: '#de401f',
                        borderWidth: 1,
                        borderDash: [3, 3],
                        label: {
                          backgroundColor: 'white',
                          color: '#de401f',
                          content: `Минимум — ${min.toLocaleString('ru-RU')}`,
                          display: true,
                          position: 'start'
                        },
                        scaleID: 'y',
                        value: min
                    },
                    max : {
                        type: 'line',
                        borderColor: '#21a74e',
                        borderWidth: 1,
                        borderDash: [3, 3],
                        label: {
                          backgroundColor: 'white',
                          color: '#21a74e',
                          content: `Максимум — ${max.toLocaleString('ru-RU')}`,
                          display: true,
                          position: 'start'
                        },
                        scaleID: 'y',
                        value: max
                    }
                }
            }
        },
    };
        
    const data = {
        labels,
        datasets: [
            {
                label: "Значение",
                cubicInterpolationMode: "monotone",
                data: labels.map((item, i) => values[item].value),
                borderColor: "rgb(190, 215, 48)",
                //backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Среднее",
                cubicInterpolationMode: "monotone",
                data: labels.map((item, i) => values[item].middle),
                borderColor: "rgb(94, 129, 172)",
                borderDash: [2, 2],
                borderWidth: 2
                //backgroundColor: "rgba(255, 99, 132, 0.5)",
            }
        ],
    };

    useEffect(() => {
        dispatch(getGraphPeriod(code))
    }, [dispatch, code])

    useEffect(() => {
        if (graph) {
            setLables(graph.labels)
            setValues(graph[type])

            const index = graph.labels[0]
            setMax(graph[type][index].max)
            setMin(graph[type][index].min)
        }
    }, [type, graph])

    //console.log(graph)

    return (
        <Paper sx={{height: 500, p: 3, position: 'relative'}} elevation={0}>
            {graph && values ? <Line style={{width: '100%'}} options={options} data={data} /> : <Preloader />}
        </Paper>
    )
}

export default PeriodGraph