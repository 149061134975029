import { Box, Divider, Paper, Typography } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getNews } from "../../store/news"

const HomeNew = () => {

    const dispatch = useDispatch()

    const news = useSelector((state) => state.news.news)

    useEffect(() => {
        dispatch(getNews());
    }, [])

    return (
        <Paper elevation={0} sx={{px: 3, pb: 2}}>
            <Typography variant="h2" fontWeight={600} sx={{fontSize: 14, py: 3}}>
                Новости
            </Typography>
            {news.map((item, i) => <OnePost news={item} key={i} />)}
            <a style={{fontSize: 14}} href="http://cerasus.ru/articles" target="_blank" rel="noreferrer">Все новости</a>
        </Paper>
    )
}

export default HomeNew

const OnePost = ({news}) => {
    return (
        <>
            <Box>
                <Typography variant="caption">
                    {news.date}
                </Typography>
                {news.url ?
                    <a href={news.url} target="_blank" rel="noreferrer"> 
                        <Typography variant="h3" fontWeight={500} sx={{fontSize: 13, lineHeight: 1.4, py: 1}}>
                            {news.title}
                        </Typography>
                    </a> : 
                    <Typography variant="h3" fontWeight={500} sx={{fontSize: 13, lineHeight: 1.4, py: 1}}>
                        {news.title}
                    </Typography>}
            </Box>
            <Divider sx={{my: 1}} />
        </>
    )
}