import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiPostRequest } from '../../services/base';
import { getShopYM } from './shopYM';
axios.defaults.withCredentials = true;

export const ymBusiness = createSlice({
    name: 'ymBusiness',
    initialState: {
        save: null
    },
    reducers: {
        setSave: (state, action) => {
            state.save = action.payload
        }
    }
})

export const { setSave } = ymBusiness.actions

export const saveYmBusinessResponse = () => (dispatch, getState) => {
    dispatch(getShopYM())
}

export const saveYMBusiness = (token) => (dispatch, getState) => {
    
    const data = new FormData()
    data.append('business', token)

    const params = {
        url: `/api/shops/ym/business`,
        data: data,
        callBack: saveYmBusinessResponse
    }

    dispatch(apiPostRequest(params))
}

export default ymBusiness.reducer