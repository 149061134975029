import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiPostRequest } from '../../services/base';
import { getShopOZ } from './shopOZ';
axios.defaults.withCredentials = true;

export const ozTokens = createSlice({
    name: 'ozTokens',
    initialState: {
        save: null
    },
    reducers: {
        setSave: (state, action) => {
            state.save = action.payload
        }
    }
})

export const { setSave } = ozTokens.actions

export const saveOzTokenResponse = () => (dispatch, getState) => {
    dispatch(getShopOZ())
}

export const saveOZToken = (clientID, apiKey) => (dispatch, getState) => {
    
    const data = new FormData()
    data.append('clientID', clientID)
    data.append('apiKey', apiKey)

    const params = {
        url: `/api/shops/oz/token`,
        data: data,
        callBack: saveOzTokenResponse
    }

    dispatch(apiPostRequest(params))
}

export default ozTokens.reducer