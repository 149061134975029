import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Preloader = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}>
            <CircularProgress />
        </Box>
    )
}

export default Preloader