import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiGetRequest, apiPostRequest } from '../../services/base';
axios.defaults.withCredentials = true;

export const products = createSlice({
    name: 'products',
    initialState: {
        count: null,
        code: null,
        list: null,
        unsorted: null,
        selected: null,
        saveLink: null
    },
    reducers: {
        setCount: (state, action) => {
            state.count = action.payload
        },
        setCode: (state, action) => {
            state.code = action.payload
        },
        setList: (state, action) => {
            state.list = action.payload
        },
        setUnsorted: (state, action) => {
            state.unsorted = action.payload
        },
        setSelected: (state, action) => {
            state.selected = action.payload
        },
        setSaveLink: (state, action) => {
            state.saveLink = action.payload
        }
    }
})

export const { setCount, setCode, setList, setUnsorted, setSelected, setSaveLink } = products.actions

export const getProductsCount = () => (dispatch, getState) => {
    const params = {
        url: '/api/seller/products/count',
        callBack: setCount
    }

    dispatch(apiGetRequest(params))
}

export const getProductsList = () => (dispatch, getState) => {
    const params = {
        url: '/api/seller/products/list',
        callBack: setList
    }

    dispatch(apiGetRequest(params))
}

export const getUnsortedList = (code) => (dispatch, getState) => {
    const params = {
        url: '/api/seller/products/unsorted?shopCode=' + code,
        callBack: setUnsorted
    }

    dispatch(apiGetRequest(params))
}

export const reloadLists = (data) => (dispatch, getState) => {
    dispatch(getProductsList())
    dispatch(getUnsortedList(data.shopCode))
    dispatch(setSaveLink("stop"))
}

export const saveProduct = (sellerProductID, shopProductID, cost, shopCode) => (dispatch, getState) => {

    const data = new FormData()

    data.append('shopProductID', shopProductID)
    data.append('sellerProductID', sellerProductID)
    data.append('cost', cost)
    data.append('shopCode', shopCode)

    const params = {
        url: '/api/seller/products/link',
        callBack: reloadLists,
        data: data
    }

    dispatch(apiPostRequest(params))
}

export default products.reducer