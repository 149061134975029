import { Link, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const PageTitle = ({title, back = null, backLabel = "Назад", size = "h3"}) => {

    const navigate = useNavigate()

    const Back = () => {
        return (
            <>
                <Link onClick={() => navigate(back)} sx={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                    <KeyboardBackspaceIcon fontSize="small" sx={{mr: 1}} />
                    <Typography>{backLabel}</Typography>
                </Link>
                
            </>

        )
    }

    return (
        <>
            { back ? <Back /> : null }
            <Typography variant={size} fontWeight={600} sx={{mb: 3}}>
                {title}
            </Typography>
        </>
    )
}

export default PageTitle