import { createSlice } from '@reduxjs/toolkit'
import { apiGetRequest } from '../../../services/base';

export const graphicHome = createSlice({
    name: 'graphicHome',
    initialState: {
        data: null
    },
    reducers: {
        setGraphHome: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setGraphHome } = graphicHome.actions

export const getGraphHome = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/graph`,
        callBack: setGraphHome
    }

    dispatch(apiGetRequest(params))
}


export default graphicHome.reducer