import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const shop = createSlice({
    name: 'shop',
    initialState: {
        graphType: 'count'
    },
    reducers: {
        setGraphType: (state, action) => {
            state.graphType = action.payload
        }
    }
})

export const { setGraphType } = shop.actions

export default shop.reducer