import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiPostRequest } from '../../services/base';
import { getShopYM } from './shopYM';
axios.defaults.withCredentials = true;

export const ymTokens = createSlice({
    name: 'ymTokens',
    initialState: {
        save: null
    },
    reducers: {
        setSave: (state, action) => {
            state.save = action.payload
        }
    }
})

export const { setSave } = ymTokens.actions

export const saveYmTokenResponse = () => (dispatch, getState) => {
    dispatch(getShopYM())
}

export const saveYMToken = (token) => (dispatch, getState) => {
    
    const data = new FormData()
    data.append('token', token)

    const params = {
        url: `/api/shops/ym/token`,
        data: data,
        callBack: saveYmTokenResponse
    }

    dispatch(apiPostRequest(params))
}

export default ymTokens.reducer