import { Box } from "@mui/material"
import Logotype from "../../../assets/logo.svg"
import { useNavigate } from "react-router-dom"

const Logo = () => {
    const navigate = useNavigate()

    const homeHandler = () => {
        navigate("/")
    }

    return (
        <Box sx={{height: 40, width: 200, mr: 3}}>
            <img onClick={homeHandler} style={{cursor: 'pointer'}} src={Logotype} alt="Logo" />
        </Box>
    )
}

export default Logo