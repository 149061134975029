import Header from "./Header"
import { Box, ThemeProvider, createTheme } from '@mui/material';
import { SnackbarProvider, useSnackbar } from "notistack";
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { resetMessage } from "../../store/message";

const theme = createTheme({
    typography: {
        fontFamily: [
        'Montserrat',
        '-apple-system',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        ].join(','),
    },
});

const Content = ({children, header = true}) => {
    const dispatch = useDispatch()

    const message = useSelector((state) => state.message.value)
    const variant = useSelector((state) => state.message.status)

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (message && variant) {
            enqueueSnackbar({message, variant});
        }
        
        dispatch(resetMessage())
    }, [message, variant, dispatch, enqueueSnackbar])

    return (
        <Box sx={{px: 4, py: 3}}>
            {header ? <Header /> : null}
            {children}
        </Box>
    )
}

const Wrapper = ({children, header = true}) => {
    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
                <Content children={children} header={header} />
            </SnackbarProvider>
        </ThemeProvider>
    )
}

export default Wrapper