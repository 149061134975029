import { createSlice } from '@reduxjs/toolkit'
import { apiGetRequest } from '../services/base';

export const alerts = createSlice({
    name: 'alerts',
    initialState: {
        alerts: []
    },
    reducers: {
        setAlerts: (state, action) => {
            state.alerts = action.payload
        }
    }
})

export const { setAlerts } = alerts.actions

export const getAlerts = () => (dispatch, getState) => {
    const params = {
        url: '/api/alerts',
        callBack: setAlerts
    }

    dispatch(apiGetRequest(params))
}

export default alerts.reducer