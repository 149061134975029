import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Badge, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { getProductsList, setSelected } from "../../../../store/settings/products"
import LinkIcon from '@mui/icons-material/Link';
import Preloader from "../../../../components/Preloader";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import styled from "@emotion/styled";

const LinkList = () => {

    const dispatch = useDispatch()

    const list = useSelector((state) => state.settingsProducts.list)
    const selected = useSelector((state) => state.settingsProducts.selected)
    //const code = useSelector((state) => state.settingsProducts.code)

    useEffect(() => {
        dispatch(getProductsList())
    }, [dispatch])

    const Checker = ({id}) => {
        const handlerClick = () => {
            if (selected === id) {
                dispatch(setSelected(null))
            } else {
                dispatch(setSelected(id))
            }
        }
        return (
            <>
                {selected !== id ? 
                    <CheckBoxOutlineBlankIcon 
                        sx={{cursor: 'pointer'}}
                        onClick={handlerClick} fontSize="small"/> : 
                    <CheckBoxIcon 
                        sx={{cursor: 'pointer'}}
                        onClick={handlerClick} fontSize="small"/> }
            </>
        )
    }

    const Row = ({data}) => {
        const StyledBadge = styled(Badge)(({ theme }) => ({
            '& .MuiBadge-badge': {
              right: -11,
              top: 10,
              border: `2px solid ${theme.palette.background.paper}`,
              padding: '0 4px',
            },
          }));

        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{verticalAlign: 'top'}}>
                    <Typography>
                        {data.title}
                    </Typography>
                </TableCell>
                <TableCell sx={{width: 160}}>
                    { data.wb.length > 0 ? <StyledBadge sx={{mr: 4}} badgeContent={data.wb.length} color="primary">WB</StyledBadge> : null }
                    { data.oz.length > 0 ? <StyledBadge sx={{mr: 4}} badgeContent={data.oz.length} color="primary">OZ</StyledBadge> : null }
                    { data.ym.length > 0 ? <StyledBadge badgeContent={data.ym.length} color="primary">YM</StyledBadge> : null }
                </TableCell>
                <TableCell>
                    <Checker id={data.id} />
                    {/* { data[code] === null ? <Checker id={data.id} /> : null } */}
                </TableCell>
            </TableRow>
        )
    }

    const ProductsTable = () => {

        if (list.length === 0) {
            return (
                <TableContainer component={Paper} elevation={0} sx={{position: 'relative'}}>
                    <Typography sx={{m:3}}>Еще нет товаров</Typography>
                </TableContainer>
            )
        }

        return (
            <TableContainer component={Paper} elevation={0} sx={{position: 'relative', minHeight: 100}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Название</TableCell>
                            <TableCell>Магазины</TableCell>
                            <TableCell>
                                <LinkIcon fontSize="small" />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map(row => <Row key={row.id} data={row} />)}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <>
            {list ? <ProductsTable /> : <Preloader />}
        </>
        
    )
}

export default LinkList