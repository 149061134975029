import { createSlice } from '@reduxjs/toolkit'
import { apiGetRequest } from '../../../services/base';

export const shopData = createSlice({
    name: 'shopData',
    initialState: {
        wb: null,
        oz: null,
        mm: null,
        ym: null
    },
    reducers: {
        setShopDataWB: (state, action) => {
            state.wb = action.payload
        },
        setShopDataOZ: (state, action) => {
            state.oz = action.payload
        },
        setShopDataMM: (state, action) => {
            state.mm = action.payload
        },
        setShopDataYM: (state, action) => {
            state.ym = action.payload
        }
    }
})

export const { setShopDataWB, setShopDataOZ, setShopDataMM, setShopDataYM } = shopData.actions

const setShopData = (data) => (dispatch, getState) => {
    
    if (data.code === 'wb') {
        dispatch(setShopDataWB(data))
    } else if (data.code === 'oz') {
        dispatch(setShopDataOZ(data))
    } else if (data.code === 'mm') {
        dispatch(setShopDataMM(data))
    } else {
        dispatch(setShopDataYM(data))
    }
}

export const getShopData = (code) => (dispatch, getState) => {
    const params = {
        url: `/api/shops/${code}`,
        callBack: setShopData
    }

    dispatch(apiGetRequest(params))
}


export default shopData.reducer