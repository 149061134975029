import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { baseURL } from '../services/base';
import { setMessage } from './message';
axios.defaults.withCredentials = true;

export const auth = createSlice({
    name: 'auth',
    initialState: {
        auth: true,
        checking: false,
        user: null,
        token: null,
        csrf: null
    },
    reducers: {
        setAuth: (state, action) => {
            state.auth = action.payload
        },
        setChecking: (state, action) => {
            state.checking = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setCSRF: (state, action) => {
            state.csrf = action.payload
        }
    }
})

export const { setAuth, setChecking, setUser, setToken, setCSRF } = auth.actions

const getLocalStorage = (key) => {
    const value = localStorage.getItem(key)

    if (value === "" || value === "null" || value === "undefind") return null

    return value
}

const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value)
}

export const logout = () => (dispatch) => {
    dispatch(setChecking(false))
    dispatch(setAuth(false))
    dispatch(setUser(null))
    dispatch(setToken(null))
    setLocalStorage('user', null)
    setLocalStorage('token', null)
}

const setLoginData = (data) => (dispatch) => {
    setLocalStorage('user', JSON.stringify(data.user))
    setLocalStorage('token', data.token)
    dispatch(setUser(data.user))
    dispatch(setToken(data.token))
    dispatch(setAuth(true))
    dispatch(setChecking(false))
}

export const login = (email, password) => (dispatch) => {
    const data = new FormData()
    data.append('email', email)
    data.append('password', password)

    axios.get(`${baseURL}/sanctum/csrf-cookie`).then(response => {
        axios.post(`${baseURL}/api/login`, data).then(response => {
            dispatch(setLoginData(response.data))
        }).catch(error => dispatch(setMessage("Ошибка авторизации", "error")));
    });
}

const checkToken = (token) => (dispatch) => {
    const headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    axios.get(`${baseURL}/sanctum/csrf-cookie`).then(response => {
        axios.get(`${baseURL}/api/user`, headers).then(response => {
            dispatch(setToken(token))
        }).catch(error => {
            dispatch(logout())
        })
    });
}

export const checkAuth = () => (dispatch, getState) => {
    let user = getState().auth.user
    let token = getState().auth.token

    dispatch(setChecking(true))

    if (!user) {
        user = getLocalStorage('user')

        if (!user) {
            dispatch(logout())
        } else {
            dispatch(setUser(JSON.parse(user)))
        }
    }

    if (!token) {
        token = getLocalStorage('token')

        if (!token) {
            dispatch(logout())
        } else {
            dispatch(checkToken(token))
        }
    }

    dispatch(setChecking(false))
}

export const register = (name, email, password, passwordConf, navigate) => (dispatch) => {
    const data = new FormData()
    data.append('name', name)
    data.append('email', email)
    data.append('password', password)
    data.append('password_confirmation', passwordConf)

    axios.get(`${baseURL}/sanctum/csrf-cookie`).then(response => {
        axios.post(`${baseURL}/api/registration`, data).then(response => {
            dispatch(setLoginData(response.data))
            navigate("/")
        }).catch(error => dispatch(setMessage("Ошибка авторизации", "error")));
    });
}

export const resetPassword = (email, navigate) => (dispatch) => {
    const data = new FormData()
    data.append('email', email)

    axios.get(`${baseURL}/sanctum/csrf-cookie`).then(response => {
        axios.post(`${baseURL}/api/forgot-password`, data).then(response => {
            console.log(data)
            //dispatch(setLoginData(response.data))
            navigate("/new-password")
        }).catch(error => dispatch(setMessage("Ошибка", "error")));
    });
}

export const setNewPassword = (code, email, password, passwordConf, navigate) => (dispatch) => {
    const data = new FormData()
    data.append('code', code)
    data.append('email', email)
    data.append('password', password)
    data.append('password_confirmation', passwordConf)

    axios.get(`${baseURL}/sanctum/csrf-cookie`).then(response => {
        axios.post(`${baseURL}/api/reset-password`, data).then(response => {
            console.log(data)
            //dispatch(setLoginData(response.data))
            navigate("/")
        }).catch(error => dispatch(setMessage("Ошибка", "error")));
    });
}

export default auth.reducer