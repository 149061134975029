import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPricerLog } from "../../../../store/services/pricerYm"
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"

const PricerLog = ({productID}) => {

    const dispatch = useDispatch()

    const pricer = useSelector((state) => state.pricerYm)
    
    //console.log(pricer)

    useEffect(() => {
        dispatch(getPricerLog(productID))
    }, [dispatch, productID])

    return (
        <>
            {pricer.wbData && pricer.wbData.length > 0 ? <ShopData title="YM" data={pricer.wbData} /> : null}
        </>
    )
}

export default PricerLog

const ShopData = ({title, data}) => {
    return (
        <Box>
            <Table sx={{ width: 'auto' }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Время</TableCell>
                        <TableCell>Цена продавца</TableCell>
                        <TableCell>Цена магазина</TableCell>
                        <TableCell>Новая цена</TableCell>
                        <TableCell>Статус</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {data.map((row, i) => (
                    <TableRow
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <Box>{row.time}</Box>
                            <Typography variant="caption">{row.date}</Typography>
                        </TableCell>
                        <TableCell>{row.seller_price}</TableCell>
                        <TableCell>{row.shop_price}</TableCell>
                        <TableCell>{row.set_price}</TableCell>
                        <TableCell>{row.status}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </Box>
    )
}