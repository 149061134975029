import { Box, Button, FormControl, OutlinedInput } from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { saveWBToken } from "../../../store/shops/wbTokens"

const WBAuth = () => {

    const shop = useSelector((state) => state.shopWB.shop)

    //console.log(shop)

    return (
        <>
            { !shop.sellerData.authData.st ? <TokenForm title="Токен «Стандарт»" type="st"/> : null }
            { !shop.sellerData.authData.sc ? <TokenForm title="Токен «Статистика»" type="sc"/> : null }
        </>
    )
}

export default WBAuth

const TokenForm = ({title, type}) => {
    
    const [token, setToken] = useState("")

    const dispatch = useDispatch()

    const handleSubmit = () => {
        dispatch(saveWBToken(type, token))
    }

    return (
        <Box sx={{margin: '0px auto 30px', width: 720}}>
            <Box display='flex' sx={{alignItems: 'center', mb: 2}}>
                <Box sx={{mr: 3, width: 100, textAlign: 'right'}}>
                    {title}
                </Box>
                <FormControl sx={{ width: '45ch' }} variant="outlined">
                    <OutlinedInput
                        id="name"
                        type='text'
                        value={token}
                        onChange={e => setToken(e.target.value)}
                    />
                </FormControl>
            </Box>
            <Box sx={{paddingLeft: '124px'}}>
                <Button disabled={token.trim() === "" || token.trim() === ""} onClick={handleSubmit} variant="contained">Сохранить</Button>
            </Box>
        </Box>
    )
}