import { createSlice } from '@reduxjs/toolkit'

export const message = createSlice({
    name: 'message',
    initialState: {
        status: null,
        value: null
    },
    reducers: {
        setValue: (state, action) => {
            state.value = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload
        }
    }
})

export const { setValue, setStatus } = message.actions

export const resetMessage = () => dispatch => {
    dispatch(setValue(null))
    dispatch(setStatus(null))
}

export const setMessage = (value, status) => dispatch => {
    dispatch(setValue(value))
    dispatch(setStatus(status))
}

export default message.reducer