import { createSlice } from '@reduxjs/toolkit'
import { apiGetRequest } from '../../../services/base';

export const graphicPeriod = createSlice({
    name: 'graphicPeriod',
    initialState: {
        wb: null,
        oz: null
    },
    reducers: {
        setGraphPeriodWB: (state, action) => {
            state.wb = action.payload
        },
        setGraphPeriodOZ: (state, action) => {
            state.oz = action.payload
        }
    }
})

export const { setGraphPeriodWB, setGraphPeriodOZ } = graphicPeriod.actions

const setGraphPeriod = (data) => (dispatch, getState) => {
    
    if (data.shopCode === 'wb') {
        dispatch(setGraphPeriodWB(data))
    } else if (data.shopCode === 'oz') {
        dispatch(setGraphPeriodOZ(data))
    } else {
        //console.log(data)
    }
}

export const getGraphPeriod = (code) => (dispatch, getState) => {
    const params = {
        url: `/api/shops/${code}/statistic/graph`,
        callBack: setGraphPeriod
    }

    dispatch(apiGetRequest(params))
}


export default graphicPeriod.reducer