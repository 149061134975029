import { Box, Typography } from "@mui/material"
import OzonAuth from "./OzonAuth"
import WBAuth from "./WBAuth"
import YMAuth from "./YMAuth"

const ShopAuth = ({code}) => {
    return (
        <Box>
            <Typography variant="h5" fontWeight={600} textAlign='center' sx={{mb: 5}}>
                Для начала работы введите данные
            </Typography>
            { code === 'oz' ? <OzonAuth /> : null }
            { code === 'wb' ? <WBAuth /> : null }
            { code === 'ym' ? <YMAuth /> : null }
        </Box>
    )
}

export default ShopAuth