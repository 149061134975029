import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPricerInfo, setTargetPrice } from "../../../../store/services/pricerYm"
import { Box, Button, TextField, Typography, InputAdornment, FormControlLabel, Switch } from "@mui/material"

const PricerForm = ({productID}) => {

    const dispatch = useDispatch()

    const pricer = useSelector((state) => state.pricerYm)

    const [target, setTarget] = useState(0)
    const [minP, setMinP] = useState(0)
    const [maxP, setMaxP] = useState(0)
    const [active, setActive] = useState(false);
    const [limit, setLimit] = useState("")
    const [addNew, setAddNew] = useState(false)

    useEffect(() => {
        dispatch(getPricerInfo(productID))
    }, [dispatch, productID])

/*     const buildLimits = () => {
        if (pricer.prodData.limits.limit === 0) {
            setLimit("Безлимит")
        } else {
            setLimit(`Активно ${pricer.prodData.limits.activeItems} из ${pricer.prodData.limits.limit}`)
        }

        if (pricer.prodData.active === 1 || pricer.prodData.limits.activeItems < pricer.prodData.limits.limit) {
            setAddNew(true);
        }
    } */

    useEffect(() => {
        console.log("YM", pricer)
        if (pricer.target) {
            setTarget(pricer.target)
            setMinP(pricer.minPercent)
            setMaxP(pricer.maxPercent)
        }

        if (pricer.prodData) {
            setActive(pricer.prodData.active === 1)
            
            if (pricer.prodData.limits.limit === 0) {
                setLimit("Безлимит")
            } else {
                setLimit(`Активно ${pricer.prodData.limits.activeItems} из ${pricer.prodData.limits.limit}`)
            }
    
            if (pricer.prodData.active === 1 || pricer.prodData.limits.activeItems < pricer.prodData.limits.limit || pricer.prodData.limits.limit === 0) {
                setAddNew(true);
            }
        }
    }, [dispatch, pricer, pricer.prodData])

    console.log(addNew);

    return (
        <Box sx={{mb: 3}}>
            <FormControlLabel sx={{mb:2}} control={<Switch disabled={!addNew} checked={active} onChange={() => setActive(!active)} />} label={`Включен для этого продукта. ${limit}`} />
            <TextField
                id="pro-cost"
                type="number"
                value={target}
                fullWidth
                sx={{mb: 2}}
                label="Целевая цена"
                onChange={e => setTarget(e.target.value)}
                variant="outlined" />
            <Typography sx={{mb: 2}}>Допустимые отклонения от целевой цены</Typography>
            <TextField
                id="pro-min"
                type="number"
                value={minP}
                sx={{mb: 2, width: 100, mr: 2}}
                InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
                label="От"
                onChange={e => setMinP(e.target.value)}
                variant="outlined" />
            <TextField
                id="pro-max"
                type="number"
                value={maxP}
                sx={{mb: 2, width: 100}}
                InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
                label="До"
                onChange={e => setMaxP(e.target.value)}
                variant="outlined" />
            <Box>
                <Button variant="contained" disabled={target === 0} onClick={() => dispatch(setTargetPrice(productID, target, minP, maxP, active))}>Сохранить</Button>
            </Box>
        </Box>
    )
}

export default PricerForm