import { configureStore } from '@reduxjs/toolkit'

import authReducer from './auth'
import messageReducer from './message'
import alertsReducer from './alerts'
import shopsReducer from './shops/shops'
import shopReducer from './shops/shop'
import wbTokensReducer from './shops/wbTokens'
import ozTokensReducer from './shops/ozTokens'
import ymTokensReducer from './shops/ymToken'
import startPageReducer from './shops/startPage'
import shopSalesReducer from './shops/shopSales'
import shopProductsReducer from './shops/shopProducts'
import marginReducer from './settings/margin'
import nalogReducer from './settings/nalog'
import productsReducer from './settings/products'
import productReducer from './settings/product'
import servicesReducer from './services/services'
import pricerWbReducer from './services/pricerWb'
import pricerOzReducer from './services/pricerOz'
import pricerYmReducer from './services/pricerYm'
import shopWBReducer from './shops/shopWB'
import shopOZReducer from './shops/shopOZ'
import shopYMReducer from './shops/shopYM'
import shopWBgraphReducer from './shops/shopWBgraph'
import graphicType from './shops/graphics/graphicType'
import graphicPeriod from './shops/graphics/graphicPeriod'
import graphicWeek from './shops/graphics/graphicWeek'
import countSales from './shops/counts/countSales'
import countProducts from './shops/counts/countProducts'
import shopData from './shops/shop/shopData'
import grahicHome from './shops/graphics/grahicHome'
import grahicHomeWeek from './shops/graphics/grahicHomeWeek'
import grahicHomePie from './shops/graphics/grahicHomePie'
import news from './news'

export default configureStore({
	reducer: {
		auth: authReducer,
		message: messageReducer,
		alerts: alertsReducer,
		shops: shopsReducer,
		shop: shopReducer,
		shopWB: shopWBReducer,
		shopWBgraph: shopWBgraphReducer,
		shopOZ: shopOZReducer,
		shopYM: shopYMReducer,
		wbTokens: wbTokensReducer,
		ozTokens: ozTokensReducer,
		ymTokens: ymTokensReducer,
		startPage: startPageReducer,
		sales: shopSalesReducer,
		shopProducts: shopProductsReducer,
		margin: marginReducer,
		nalog: nalogReducer,
		settingsProducts: productsReducer,
		settingsProduct: productReducer,
		services: servicesReducer,
		pricerWb: pricerWbReducer,
		pricerOz: pricerOzReducer,
		pricerYm: pricerYmReducer,
		graphicType,
		graphicPeriod,
		graphicWeek,
		countSales,
		countProducts,
		shopData,
		grahicHome,
		grahicHomeWeek,
		grahicHomePie,
		news
	},
})