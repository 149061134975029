import { useState } from "react"
import { useDispatch } from "react-redux";
import Wrapper from "../../components/Wrapper";
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { login } from "../../store/auth";

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState("")
    const [_email, _setEmail] = useState(false)
    const [password, setPassword] = useState("")
    const [_password, _setPassword] = useState(false)

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlerInputEmail = (value) => {
        value = value.trim()
        setEmail(value)
        _setEmail(false)
    }

    const handlerInputPassword = (value) => {
        value = value.trim()
        setPassword(value)
        _setPassword(false)
    }

    
    const validateEmail = (email) => {
        const emailRegexp = new RegExp(
            //eslint-disable-next-line
            /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i
        )
        
        return emailRegexp.test(email)
    };

    const handlerSubmit = () => {
        if (email.length === 0) {
            _setEmail(true)
        } else if (validateEmail(email) === false) {
            _setEmail(true)
        } else if (password.length < 7) {
            _setPassword(true)
        } else {
            dispatch(login(email, password))
        }
    }

    return (
        <Wrapper header={false}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                style={{ minHeight: '90vh' }}>
                <Grid item alignSelf='end'>
                    <Box sx={{pr : 3, pt : 2}}>
                        <Typography>
                            <Link onClick={() => navigate('/register')} sx={{cursor: 'pointer'}}>Регистрация</Link>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="h4" fontWeight={600} textAlign='center' sx={{mb: 3}}>
                        Войти
                    </Typography>
                    <Box>
                        <FormControl sx={{ mb: 2, width: '45ch' }} variant="outlined">
                            <InputLabel htmlFor="email">Email</InputLabel>
                            <OutlinedInput
                                id="email"
                                type='text'
                                value={email}
                                error={_email}
                                onChange={e => handlerInputEmail(e.target.value)}
                                label="Email"
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl sx={{ mb: 2, width: '45ch' }} variant="outlined">
                            <InputLabel htmlFor="assword">Пароль</InputLabel>
                            <OutlinedInput
                            id="password"
                            value={password}
                            error={_password}
                            onChange={e => handlerInputPassword(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Пароль"
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <Button onClick={handlerSubmit} variant="contained">Войти</Button>
                    </Box>
                    <Box sx={{mt: 3}}>
                        <Link href="/reset-password" sx={{cursor: 'pointer'}}>Забыл пароль</Link>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{pb : 2}}>
                        <Typography>
                            <Link sx={{cursor: 'pointer'}}>На сайт</Link>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Wrapper>
    )
}

export default Login