import axios from "axios";
import { setMessage } from "../store/message";
import { checkAuth } from "../store/auth";

axios.defaults.withCredentials = true;

//export const baseURL = 'http://localhost:8000'
export const baseURL = 'http://cerasus.ru'

export const apiGetRequest = (params) => (dispatch, getState) => {

    const token = getState().auth.token
    //const csrf = getState().auth.csrf

    const headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

/*     axios.get(`${baseURL}/sanctum/csrf-cookie`).then(response => {
        axios.get(`${baseURL}${params.url}`, headers).then(response => {
            dispatch(params.callBack(response.data))
        }).catch(error => {
            if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
                dispatch(checkAuth())
            } else {
                dispatch(setMessage("Ошибка загрузки", "error"));
            }
        })
    }); */
    axios.get(`${baseURL}${params.url}`, headers).then(response => {
        //console.log(response.data)
        dispatch(params.callBack(response.data))
    }).catch(error => {
        console.log(error)
        if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
            dispatch(checkAuth())
        } else {
            dispatch(setMessage("Ошибка загрузки", "error"));
        }
    })
}

export const apiPostRequest = (params) => (dispatch, getState) => {

    const token = getState().auth.token
    //const csrf = getState().auth.csrf

    const headers = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    axios.get(`${baseURL}/sanctum/csrf-cookie`).then(response => {
        axios.post(`${baseURL}${params.url}`, params.data, headers).then(response => {
            dispatch(setMessage("Сохранено", "success"));
            dispatch(params.callBack(response.data))
        }).catch(error => {
            if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
                dispatch(checkAuth())
            } else {
                dispatch(setMessage("Ошибка загрузки", "error"));
            }
        })
    });
}