import { useEffect, useState } from "react"
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { orange, lightBlue, lime, red } from '@mui/material/colors';
import { Box, Typography } from "@mui/material";

const Progress = ({values}) => {

    const [k, setK] = useState(0)
    const [color, setColor] = useState(red[500])

    useEffect(() => {
        const max = values.max >= values.value ? values.max : values.value
        setK(100 / max)

        if (values.value >= values.min) {
            setColor(orange[500])
        } else if (values.value >= values.mid) {
            setColor(lightBlue[500])
        } else if (values.value >= values.max) {
            setColor(lime[500])
        } else {
            setColor(red[500])
        }
    }, [values])

    if (k === 0) return null

    return (
        <div style={{position: 'relative'}}>
            <div style={{width: '90%', height: 40, backgroundColor: '#efefef', borderRadius: 6, position: 'relative'}}>
                <div className="blobWeekGraph" style={{left: `${values.min * k}%`, borderRadius: 6}}>
                    {values.value < values.min ? <ArrowDropDownCircleIcon sx={{position: 'absolute', color: orange[500]}}  /> :  <CheckCircleIcon sx={{position: 'absolute', color: orange[500]}}  />}
                    <div className="blobLabelName">Минимум*</div>
                    <div className="blobLabelVal">{values.min.toLocaleString('ru-RU')}</div>
                </div>
                <div className="blobWeekGraph" style={{left: `${values.mid * k}%`, borderRadius: 6}}>
                    {values.value < values.mid ? <ArrowDropDownCircleIcon sx={{position: 'absolute', color: lightBlue[500]}}  /> :  <CheckCircleIcon sx={{position: 'absolute', color: lightBlue[500]}}  />}
                    <div className="blobLabelName">Среднее*</div>
                    <div className="blobLabelVal">{values.mid.toLocaleString('ru-RU')}</div>
                </div>
                <div className="blobWeekGraph" style={{left: `${values.max * k}%`, borderRadius: 6}}>
                {values.value < values.max ? <ArrowDropDownCircleIcon sx={{position: 'absolute', color: lime[500]}}  /> :  <CheckCircleIcon sx={{position: 'absolute', color: lime[500]}}  />}
                    <div className="blobLabelName">Максимум*</div>
                    <div className="blobLabelVal">{values.max.toLocaleString('ru-RU')}</div>
                </div>
                <div style={{position: 'absolute', backgroundColor: color, height: 40, top: 0, left: 0, borderRadius: 6, width: `${values.value * k}%`}}>
                    <div className="valLabel">{values.value.toLocaleString('ru-RU')}</div>
                </div>
            </div>
            <Box>
                <Typography variant="caption">* — за последние 6 недель</Typography>
            </Box>
        </div>
    )
}

export default Progress