import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiGetRequest } from '../../services/base';
axios.defaults.withCredentials = true;

export const shops = createSlice({
    name: 'shops',
    initialState: {
        shops: []
    },
    reducers: {
        setShops: (state, action) => {
            state.shops = action.payload
        }
    }
})

export const { setShops } = shops.actions

export const getShops = () => (dispatch, getState) => {
    const params = {
        url: '/api/shops',
        callBack: setShops
    }

    dispatch(apiGetRequest(params))
}

export default shops.reducer