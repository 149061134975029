import { Box, Divider, Paper, Typography } from "@mui/material"
import { useSelector } from "react-redux"

const ProductSaleData = () => {
    
    const productSales = useSelector((state) => state.shopProducts.productSales)

    return (
        <Paper elevation={0} sx={{mt: 3, p: 3}}>
            <Box sx={{mb: 2}}>
                <Typography variant="button" fontWeight={600}>
                    Продажи за период
                </Typography>
                <Box sx={{display: 'flex'}}>
                    <Box sx={{flex: '0 0 50%'}}>
                        <Typography>
                            Количество
                        </Typography>
                        <Typography variant="h5" fontWeight={600}>
                            {productSales.periodData.count}
                        </Typography>
                    </Box>
                    <Box sx={{flex: '0 0 50%'}}>
                        <Typography>
                            На сумму
                        </Typography>
                        <Typography variant="h5" fontWeight={600}>
                            {productSales.periodData.sum.toLocaleString('ru-RU')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Divider/>
            <Box sx={{my: 2}}>
                <Typography variant="button" fontWeight={600}>
                    Маржа за период
                </Typography>
                <Box sx={{display: 'flex'}}>
                    <Box sx={{flex: '0 0 50%'}}>
                        <Typography>
                            Без учета налогов
                        </Typography>
                        <Typography variant="h5" fontWeight={600}>
                            {productSales.periodData.marginBN.toLocaleString('ru-RU')}
                        </Typography>
                    </Box>
                    <Box sx={{flex: '0 0 50%'}}>
                        <Typography>
                            После выплаты налогов
                        </Typography>
                        <Typography variant="h5" fontWeight={600}>
                            {productSales.periodData.marginAN.toLocaleString('ru-RU')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Divider/>
            <Box sx={{my: 2}}>
                <Typography variant="button" fontWeight={600}>
                    Средняя маржа за единицу
                </Typography>
                <Box sx={{display: 'flex'}}>
                    <Box sx={{flex: '0 0 50%'}}>
                        <Typography>
                            Без учета налогов
                        </Typography>
                        <Typography variant="h5" fontWeight={600}>
                            {productSales.periodData.midMarginBN.toLocaleString('ru-RU')}
                        </Typography>
                    </Box>
                    <Box sx={{flex: '0 0 50%'}}>
                        <Typography>
                            После выплаты налогов
                        </Typography>
                        <Typography variant="h5" fontWeight={600}>
                            {productSales.periodData.midMarginAN.toLocaleString('ru-RU')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Divider/>
            <Box sx={{mt:2}}>
                <Typography variant="button" fontWeight={600}>
                    Маржинальность за период
                </Typography>
                <Box sx={{display: 'flex'}}>
                    <Box sx={{flex: '0 0 50%'}}>
                        <Typography>
                            Без учета налогов
                        </Typography>
                        <Typography variant="h5" fontWeight={600}>
                            {productSales.periodData.percentBN.toLocaleString('ru-RU')} %
                        </Typography>
                    </Box>
                    <Box sx={{flex: '0 0 50%'}}>
                        <Typography>
                            После выплаты налогов
                        </Typography>
                        <Typography variant="h5" fontWeight={600}>
                            {productSales.periodData.percentAN.toLocaleString('ru-RU')} %
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}

export default ProductSaleData