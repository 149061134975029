import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiGetRequest, apiPostRequest } from '../../services/base';
axios.defaults.withCredentials = true;

export const margin = createSlice({
    name: 'margin',
    initialState: {
        min: null,
        mid: null,
        max: null
    },
    reducers: {
        setMin: (state, action) => {
            state.min = action.payload
        },
        setMid: (state, action) => {
            state.mid = action.payload
        },
        setMax: (state, action) => {
            state.max = action.payload
        }
    }
})

export const { setMin, setMid, setMax } = margin.actions

export const setValues = (data) => (dispatch, getState) => {
    dispatch(setMin(data.min))
    dispatch(setMid(data.mid))
    dispatch(setMax(data.max))
}

export const getMargin = () => (dispatch, getState) => {
    const params = {
        url: `/api/seller/margin`,
        callBack: setValues
    }

    dispatch(apiGetRequest(params))
}

export const saveMargin = (key, value) => (dispatch, getState) => {

    const data = new FormData()
    data.append('key', key)
    data.append('value', value)

    const params = {
        url: `/api/seller/margin`,
        callBack: getMargin,
        data: data
    }

    dispatch(apiPostRequest(params))
}

export default margin.reducer