import { createSlice } from '@reduxjs/toolkit'
import { apiGetRequest } from '../../../services/base';

export const graphicWeek = createSlice({
    name: 'graphicWeek',
    initialState: {
        wb: null,
        oz: null
    },
    reducers: {
        setGraphWeekWB: (state, action) => {
            state.wb = action.payload
        },
        setGraphWeekOZ: (state, action) => {
            state.oz = action.payload
        }
    }
})

export const { setGraphWeekWB, setGraphWeekOZ } = graphicWeek.actions

const setGraphWeek = (data) => (dispatch, getState) => {
    
    if (data.shopCode === 'wb') {
        dispatch(setGraphWeekWB(data))
    } else if (data.shopCode === 'oz') {
        dispatch(setGraphWeekOZ(data))
    } else {
        //console.log(data)
    }
}

export const getGraphWeek = (code) => (dispatch, getState) => {
    const params = {
        url: `/api/shops/${code}/statistic/week`,
        callBack: setGraphWeek
    }

    dispatch(apiGetRequest(params))
}


export default graphicWeek.reducer