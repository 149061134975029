import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Preloader from "../../../components/Preloader"
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ListIcon from '@mui/icons-material/List';
import GridViewIcon from '@mui/icons-material/GridView';
import { getProducts } from "../../../store/shops/shopProducts"
import PageTitle from "../../../components/Pages/PageTitle"

const ShopProducts = () => {
    
    const {code} = useParams()
    const dispatch = useDispatch()

    const [view, setView] = useState('grid')

    const products = useSelector((state) => state.shopProducts.items)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getProducts(code))
    }, [code, dispatch])

    const Row = ({data}) => {
        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {view === 'full-list' ?
                <TableCell width={120}>
                    <img style={{width: '60px'}} src={`${data.image}`} alt={data.title} />
                </TableCell> : null }
                <TableCell sx={{verticalAlign: 'top'}}>
                    <Typography sx={{cursor: 'pointer'}} color='primary' onClick={() => navigate(`${data.id}`)}>
                        {data.title}
                    </Typography>
                </TableCell>
                <TableCell>
                </TableCell>
            </TableRow>
        )
    }

    const ProductsTable = () => {
        return (
            <TableContainer component={Paper} elevation={0} sx={{position: 'relative', minHeight: 100}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {view === 'full-list' ? <TableCell></TableCell> : null }
                            <TableCell>Название</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map(row => <Row key={row.id} data={row} />)}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const Item = ({data}) => {
        return (
            <Paper sx={{flex: '0 0 200px', mr: 2, mb: 2, p:3, display: 'flex', justifyContent:'space-between', flexDirection: 'column', cursor: 'pointer'}} elevation={0} onClick={() => navigate(`${data.id}`)}>
                <Box>
                    <img style={{width: '100%'}} src={`${data.image}`} alt={data.title} />
                    <Typography sx={{mb: 1}}>
                        {data.title}
                    </Typography>
                </Box>
            </Paper>
        )
    }

    const ProductsGrid = () => {
        return (
            <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                {products.map(item => <Item key={item.id} data={item} />)}
            </Box>
        )
    }

    const ViewList = () => {
        if (view === 'grid') {
            return (
                <ProductsGrid />
            )
        } else {
            return (
                <ProductsTable />
            )
        }
    }

    const ViewSelector = () => {
        return (
            <Box sx={{mb:2}}>
                <IconButton 
                    aria-label="list" 
                    color={view === 'list' ? "primary" : ""}
                    onClick={() => setView('list')}>
                    <ListIcon />
                </IconButton>
                <IconButton 
                    aria-label="full-list" 
                    color={view === 'full-list' ? "primary" : ""}
                    onClick={() => setView('full-list')}>
                    <FormatListBulletedIcon />
                </IconButton>
                <IconButton 
                    aria-label="grid" 
                    color={view === 'grid' ? "primary" : ""}
                    onClick={() => setView('grid')}>
                    <GridViewIcon />
                </IconButton>
            </Box>
        )
    }

    return (
        <>
            <PageTitle title="Товары" back={`/shops/${code}`} size="h5" />
            <ViewSelector />
            {products ? <ViewList /> : <Preloader />}
        </>
    )
}

export default ShopProducts