import { createSlice } from '@reduxjs/toolkit'
import { apiGetRequest } from '../../../services/base';

export const graphicHomePie = createSlice({
    name: 'graphicHomePie',
    initialState: {
        data: null
    },
    reducers: {
        setGraphHome: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setGraphHome } = graphicHomePie.actions

export const getGraphHomePie = () => (dispatch, getState) => {
    const params = {
        url: `/api/shops/dataset`,
        callBack: setGraphHome
    }

    dispatch(apiGetRequest(params))
}


export default graphicHomePie.reducer