import { Box, Paper, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import Preloader from "../../../../components/Preloader"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { getCountProducts } from "../../../../store/shops/counts/countProducts"

const CountProducts = ({code}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const count = useSelector((state) => state.countProducts[code])

    useEffect(() => {
        dispatch(getCountProducts(code))
    }, [dispatch, code])

    return (
        <Paper sx={{py: 2, px: 3, mb: 3, cursor: 'pointer'}} onClick={() => navigate('products')} elevation={0}>
            <Typography variant="h6">
                Товаров в магазине
            </Typography>
            {count ? 
                <Typography variant="h1" fontWeight={600}>
                    {count}
                </Typography> :
                <Box sx={{position: 'relative', minHeight: 100}}>
                    <Preloader />
                </Box> }
        </Paper>
    )
}

export default CountProducts