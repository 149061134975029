import { Grid } from "@mui/material"
import PageTitle from "../../../../components/Pages/PageTitle"
import PageCounter from "./PageCounter"
import ProductsList from "./ProductList"

const SellerProductsPage = () => {

    return (
        <>
            <PageTitle title="Мои товары" back="/settings" />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PageCounter />
                </Grid>
                <Grid item xs={12}>
                    <ProductsList />
                </Grid>
            </Grid>
        </>
    )
}

export default SellerProductsPage