import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Login from "../../screen/Login";
import { useEffect } from "react";
import { checkAuth } from "../../store/auth";
import Wrapper from "../Wrapper";
import Preloader from "../Preloader";

const Auth = ({children}) => {
    
    const dispatch = useDispatch();
    const location = useLocation();
    const auth = useSelector((state) => state.auth.auth)
    const token = useSelector((state) => state.auth.token)

    useEffect(() => {
        dispatch(checkAuth())
    }, [dispatch, location.pathname])

    if (!auth) {
        return (
            <Login />
        )
    }

    if (!token) {
        return (
            <Preloader />
        )
    }

    return (
        <Wrapper>{children}</Wrapper>
    )
}

export default Auth