import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiGetRequest, apiPostRequest } from '../../services/base';
axios.defaults.withCredentials = true;

export const product = createSlice({
    name: 'product',
    initialState: {
        data: null
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setData } = product.actions

export const getProduct = (id) => (dispatch, getState) => {
    const params = {
        url: '/api/seller/products/' + id,
        callBack: setData
    }

    dispatch(apiGetRequest(params))
}

export const updateProduct = (id, title, comment) => (dispatch, getState) => {
    const data = new FormData()

    data.append('title', title)
    data.append('comment', comment)

    const params = {
        url: '/api/seller/products/' + id,
        callBack: getProduct,
        data: data
    }

    dispatch(apiPostRequest(params))
}

export const updateShopProductURL = (id, shopCode, shopProductID, url) => (dispatch, getState) => {
    const data = new FormData()

    data.append('shopCode', shopCode)
    data.append('shopProductID', shopProductID)
    data.append('url', url)

    const params = {
        url: '/api/seller/products/' + id + '/url',
        callBack: getProduct,
        data: data
    }

    dispatch(apiPostRequest(params))
}

export const updateCost = (id, price) => (dispatch, getState) => {
    const data = new FormData()

    data.append('price', price)

    const params = {
        url: `/api/seller/products/${id}/price`,
        callBack: getProduct,
        data: data
    }

    dispatch(apiPostRequest(params))
}

export const unlinkProd = (id, linkID) => (dispatch, getState) => {
    const data = new FormData()

    data.append('linkID', linkID)

    const params = {
        url: `/api/seller/products/${id}/unlink`,
        callBack: getProduct,
        data: data
    }

    dispatch(apiPostRequest(params))
}

export default product.reducer