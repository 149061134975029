import { Box, Button, FormControl, OutlinedInput } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { saveYMBusiness } from "../../../store/shops/ymBusiness"

const YMBusiness = () => {

    const dispatch = useDispatch()

    const [busID, setBusID] = useState("")

    const handleSubmit = () => {
        dispatch(saveYMBusiness(busID))
    }

    return (
        <Box sx={{margin: '0px auto', width: 720}}>
            <Box display='flex' sx={{alignItems: 'center', mb: 2}}>
                <Box sx={{mr: 3, width: 100, textAlign: 'right'}}>
                    Бизнес ID
                </Box>
                <FormControl sx={{ width: '45ch' }} variant="outlined">
                    <OutlinedInput
                        id="name"
                        type='text'
                        value={busID}
                        onChange={e => setBusID(e.target.value)}
                    />
                </FormControl>
            </Box>
            <Box sx={{paddingLeft: '124px'}}>
                <Button disabled={busID.trim() === ""} onClick={handleSubmit} variant="contained">Сохранить</Button>
            </Box>
        </Box>
    )
}

export default YMBusiness