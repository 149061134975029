import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from "react";
import { unlinkProd, updateShopProductURL } from "../../../store/settings/product";
import EditIcon from '@mui/icons-material/Edit';

const Links = ({id}) => {

    const dispatch = useDispatch();
    const product = useSelector((state) => state.settingsProduct.data)

    const [open, setOpen] = useState(false);
    const [unlinkID, setUnlinkID] = useState(null)

    const handleClickOpen = (linkID) => {
      setOpen(true);
      setUnlinkID(linkID);
    };
  
    const handleClose = () => {
      setOpen(false);
      setUnlinkID(null)
    };

    const handleUnlink = () => {
        if (unlinkID) {
            dispatch(unlinkProd(id, unlinkID))
        }
        setOpen(false);
        setUnlinkID(null)
    };

    return (
        <>
            <Paper sx={{p:3, mb: 4}} elevation={0}>
                <Typography variant="h6" sx={{mb:3}}>Связанные товары</Typography>
                { product.links.length > 0 ?
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Магазин</TableCell>
                            <TableCell>Товар</TableCell>
                            <TableCell>Ссылка</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {product.links.map((el, i) => <TableRow key={i}>
                                                        <TableCell>{el.shopCode.toUpperCase()} </TableCell>
                                                        <TableCell>{el.shopProduct.title} ({el.shopProduct.article})</TableCell>
                                                        <TableCell>
                                                            
                                                            <DialogURL id={id} link={el} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Tooltip title="Открепить продукт">
                                                                <IconButton aria-label="delete" onClick={() => handleClickOpen(el.linkID)}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                        </TableRow>)}
                    </TableBody>
                </Table> : null }
            </Paper>

            <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Отвязать товар?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Товар нужно будет завести как новый или привязать к другому
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button onClick={handleUnlink} autoFocus>Отвязать</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Links

const DialogURL = ({id, link}) => {

    const [open, setOpen] = useState(false)
    const [url, setUrl] = useState("")

    const dispatch = useDispatch()

    useEffect(() => {
        if (link.shopProduct.url) {
            setUrl(link.shopProduct.url)
        }
    }, [id, link])

    const handleClose = () => {
        setOpen(false);

        if (link.shopProduct.url) {
            setUrl(link.shopProduct.url)
        } else {
            setUrl("")
        }
    };

    const saveURL = () => {
        dispatch(updateShopProductURL(id, link.shopCode, link.shopProduct.id, url))
        handleClose()
    }

    const deleteURL = () => {
        dispatch(updateShopProductURL(id, link.shopCode, link.shopProduct.id, null))
        handleClose()
    }

    return (
        <>
            <Box>
                {link.shopProduct.url}
                <Tooltip title="Задать ссылку">
                    <IconButton aria-label="edit" onClick={() => setOpen(true)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Dialog
                open={open}
                fullWidth
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                {"Задать ссылку"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ссылка на страницу магазина
                    </DialogContentText>
                    <TextField
                        id="pro-url"
                        value={url}
                        fullWidth
                        label="URL"
                        onChange={e => setUrl(e.target.value)}
                        variant="outlined" />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    {link.shopProduct.url && <Button onClick={deleteURL}>Удалить URL</Button>}
                    <Button onClick={saveURL} autoFocus>Сохранить URL</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}