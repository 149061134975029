import { Box, Button, FormControl, OutlinedInput } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { saveYMToken } from "../../../store/shops/ymToken"

const YMAuth = () => {

    const dispatch = useDispatch()

    const [token, setToken] = useState("")

    const handleSubmit = () => {
        dispatch(saveYMToken(token))
    }

    return (
        <Box sx={{margin: '0px auto', width: 720}}>
            <Box display='flex' sx={{alignItems: 'center', mb: 2}}>
                <Box sx={{mr: 3, width: 100, textAlign: 'right'}}>
                    Token
                </Box>
                <FormControl sx={{ width: '45ch' }} variant="outlined">
                    <OutlinedInput
                        id="name"
                        type='text'
                        value={token}
                        onChange={e => setToken(e.target.value)}
                    />
                </FormControl>
            </Box>
            <Box sx={{paddingLeft: '124px'}}>
                <Button disabled={token.trim() === ""} onClick={handleSubmit} variant="contained">Сохранить</Button>
            </Box>
        </Box>
    )
}

export default YMAuth