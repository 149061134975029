import { Box, Paper, Typography } from "@mui/material"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useEffect } from "react";

import { Doughnut } from 'react-chartjs-2';
import { useDispatch, useSelector } from "react-redux";
import { getGraphHomePie } from "../../store/shops/graphics/grahicHomePie";
import Preloader from "../../components/Preloader";

const HomePie = () => {

    ChartJS.register(ArcElement, Tooltip, Legend);

    const data = useSelector((state) => state.grahicHomePie.data)

    const dispatch = useDispatch();

    console.log(data)

    useEffect(() => {
        dispatch(getGraphHomePie())
    }, [])

    if (!data) {
        console.log("STOP")
        return <Preloader />
    }

    console.log("DO")

    const dataPay = {
        labels: [
            'WB',
            'OZON'
        ],
        datasets: [{
            label: 'Выплаты, %',
            data: [data.pay.wb, data.pay.oz],
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)'
            ],
            hoverOffset: 4
        }]
    };

    const dataMargin = {
        labels: [
            'WB',
            'OZON'
        ],
        datasets: [{
            label: 'Маржа, %',
            data: [data.margin.wb, data.margin.oz],
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)'
            ],
            hoverOffset: 4
        }]
    };

    return (
        <Paper elevation={0} sx={{p: 3}}>
            <Box sx={{mb: 3}}>
                <Typography variant="h2" fontWeight={600} sx={{fontSize: 14, textAlign: 'center', mb: 1}}>Денежный поток</Typography>
                <Doughnut data={dataPay} />
            </Box>
            <Box>
                <Typography variant="h2" fontWeight={600} sx={{fontSize: 14, textAlign: 'center', mb: 1}}>Маржа</Typography>
                <Doughnut data={dataMargin} />
            </Box>
        </Paper>
    )
}

export default HomePie