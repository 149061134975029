import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { AppBar, Box, Button, Dialog, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Slide, Slider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import Preloader from "../../../components/Preloader";
import { red, amber, lime, lightGreen } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from "../../../components/DatePicker";
import PageTitle from "../../../components/Pages/PageTitle";
import { getSale, getSales, setMax, setMin, setSaleData, setSales, setSort } from "../../../store/shops/shopSales";
import { getMargin } from "../../../store/settings/margin";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Sales = () => {

    const {code} = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams();

    const f = searchParams.get('f') !== null ? searchParams.get('f') : ""
    const t = searchParams.get('t') !== null ? searchParams.get('t') : ""

    const sales = useSelector((state) => state.sales.sales)
    const margin = useSelector((state) => state.margin)

    const [open, setOpen] = useState(false)
    const [sale, setSale] = useState(null)

    useEffect(() => {
        dispatch(getMargin())
    }, [dispatch])

    const handleClose = () => {
        setOpen(false)
        setSale(null)
        dispatch(setSaleData(null))
    };

    const handleOpen = (data) => {
        setSale(data)
        setOpen(true)
        dispatch(getSale(code, data.srid))
    };

    const sort = useSelector((state) => state.sales.sort)
    const min = useSelector((state) => state.sales.min)
    const max = useSelector((state) => state.sales.max)
    
    const [table, setTable] = useState([])

    useEffect(() => {
        return () => {
            dispatch(setSales(null))
        }
    }, [dispatch])

    useEffect(() => {
        dispatch(getSales(code, f, t))
    }, [f, t, code, dispatch])

    useEffect(() => {
        const sliceSales = () => {
            let tmp = []
    
            sales.sales.forEach(item => {
                if (item.reportPercent >= min && item.reportPercent <= max) {
                    tmp.push(item)
                }
            })
    
            return tmp
        }
    
        const sortSales = (array) => {
            if (sort === 2) {
                array.sort((a, b) => {
                    const nameA = a.product.title.toUpperCase(); 
                    const nameB = b.product.title.toUpperCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  });
            } else if (sort === 3) {
                return array.sort((a, b) => a.reportPercent - b.reportPercent);
            }
            return array
        }

        if (sales) {
            if (sales.reportData) {
                setTable(sortSales(sliceSales()))
            } else {
                setTable(sales.sales)
            }
        }
    }, [sales, min, max, sort])

    const getColorMargin = (value) => {
        if (margin.min === 0 && margin.mid === 0 && margin.max === 0) {
            return '#fff'
        } else {
            if (value < margin.min) {
                return red[500];
            } else if (value < margin.mid) {
                return amber[500];
            } else if (value < margin.max) {
                return lime[500];
            } else {
                return lightGreen[500]
            }
        }
    }

    const Row = ({data}) => {

        //console.log(data)

        const [prognoz, setPrognoz] = useState('#fff')
        const [report, setReport] = useState('#fff')

        useEffect(() => {
            setPrognoz(getColorMargin(data.prognozPercent))
            setReport(getColorMargin(data.reportPercent))
        }, [data.prognozPercent, data.reportPercent])

        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                    {data.date}
                </TableCell>
                <TableCell onClick={() => handleOpen(data)} sx={{cursor: 'pointer'}}>
                    {data.product.title}
                </TableCell>
                <TableCell>{data.saleRealisation}</TableCell>
                {code === 'wb' ? <TableCell>{data.saleForPay}</TableCell> : null}
                {code === 'wb' ? <><TableCell sx={{backgroundColor: prognoz}}>{data.prognozMargin}</TableCell>
                <TableCell sx={{backgroundColor: prognoz}}>{data.prognozPercent} %</TableCell></> : null}

                {
                    data.reportPay === 0 && data.reportDelivery === 0 && data.reportMargin === 0 && data.reportPercent === 0 ?
                    <TableCell colSpan={4} sx={{textAlign: 'center'}}>Ждем отчет магазина</TableCell> :
                    <>
                        <TableCell>{data.reportPay}</TableCell>
                        {code === 'wb' ? <TableCell>{data.reportDelivery}</TableCell> : null}
                        
                        <TableCell sx={{backgroundColor: report}}>{data.reportMargin}</TableCell>
                        <TableCell sx={{backgroundColor: report}}>{data.reportPercent} %</TableCell>
                    </>
                }
            </TableRow>
        )
    }

    const SalesTable = () => {
        return (
            <TableContainer component={Paper} sx={{mt: 4}} elevation={0}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        {}
                        <TableRow>
                            <TableCell colSpan={2}></TableCell>
                            {code === 'wb' ? <TableCell colSpan={2}>До уточнения</TableCell> : null}
                            {code === 'wb' ? <TableCell colSpan={2}>Прогноз</TableCell> : null}
                            
                            <TableCell colSpan={4}>Итог</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Дата и время</TableCell>
                            <TableCell>Товар</TableCell>
                            <TableCell>Реализация</TableCell>
                            <TableCell>К выплате</TableCell>
                            
                            <TableCell>Маржа</TableCell>
                            <TableCell>Маржинальность</TableCell>
                            {code === 'wb' ? <><TableCell>Выплата</TableCell>
                            <TableCell>Логистика</TableCell>
                            <TableCell>Маржа</TableCell>
                            <TableCell>Маржинальность</TableCell></> : null}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sales.sales.map((row, i) => <Row key={i} data={row} />)}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const datePickerChange = (e) => {
        navigate(`?f=${e.f}&t=${e.t}`)
    }

    if (!sales) return <Preloader />

    return (
        <Box>
            <PageTitle title='Продажи' back={`/shops/${code}`} size="h5" />
            <DatePicker from={f} to={t} submit={datePickerChange} />
            {/* {sales.reportData ? <Filters /> : null } */}
            {sales.sales.length > 0 ? <SalesTable /> : <Box sx={{mt: 3}}>Ничего нет</Box>}
            {open && sale ? 
            <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{display: 'flex'}}>
                        <Typography sx={{ ml: 2, flex: 1, pt: "1px" }} variant="h6" component="div">
                            {sale.product.title}
                        </Typography>
                        <Button variant="outlined" sx={{color: 'white'}} onClick={() => navigate(`/shops/${sale.product.shopCode}/products/${sale.product.shop_product_id}?f=${f}&t=${t}`)}>Перейти к товару</Button>
                    </Box>
                </Toolbar>
                </AppBar>
                <Box sx={{p:4}}>
                    <Box sx={{mb: 2}}>ID записи (srid) — {sale.srid}</Box>
                    <SaleData />
                </Box>
            </Dialog> : null }
        </Box>
    )
}

export default Sales

const Filters = () => {

    const dispatch = useDispatch()

    const sort = useSelector((state) => state.sales.sort)
    const min = useSelector((state) => state.sales.min)
    const max = useSelector((state) => state.sales.max)

    const handleChange = (event) => {
        dispatch(setSort(event.target.value));
    };

    const [value, setValue] = useState([min, max]);

    const handleChangeSlide = (event, newValue) => {
        setValue(newValue);
    };

    function valuetext() {
        return `${value} %`;
    }

    const handleSubmit = () => {
        dispatch(setMin(value[0]))
        dispatch(setMax(value[1]))
    }

    return (
        <Box sx={{display: 'flex', alignItems: 'center', mt: 3}}>
            <FormControl variant="outlined" sx={{ minWidth: 120, mr: 4 }}>
                <InputLabel id="demo-simple-select-outlined-label">Сортировка</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={sort}
                    onChange={handleChange}
                    label="Сортировка"
                    >
                    <MenuItem value={1}>По времени</MenuItem>
                    <MenuItem value={2}>По названию</MenuItem>
                    <MenuItem value={3}>По маржинальности</MenuItem>
                </Select>
            </FormControl>
            <Box sx={{mr: 2}}>Маржинальность</Box>
            <Box sx={{ width: 300, mr: 4 }}>
                <Slider
                    getAriaLabel={() => 'Temperature range'}
                    value={value}
                    onChange={handleChangeSlide}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                />
            </Box>
            <Box>
                <Button onClick={handleSubmit}>Применить</Button>
            </Box>
        </Box>
    )
}

const SaleData = () => {
    
    const saleData = useSelector((state) => state.sales.sale)

    //console.log(saleData)

    if (!saleData) {
        return (
            <Preloader />
        )
    }

    return (
        <Box>
            <Typography variant="h4">Данные о продаже</Typography>
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell>Дата</TableCell>
                        <TableCell>{saleData.sale.date}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>ID продажи</TableCell>
                        <TableCell>{saleData.sale.sale_id}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Цена до согласованной скидки</TableCell>
                        <TableCell>{saleData.sale.total_price}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Согласованный итоговый дисконт</TableCell>
                        <TableCell>{saleData.sale.discount_percent}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Фактическая цена заказа с учетом всех скидок</TableCell>
                        <TableCell>{saleData.sale.finished_price}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>К перечислению продавцу</TableCell>
                        <TableCell>{saleData.sale.for_pay}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Склад</TableCell>
                        <TableCell>{saleData.sale.warehouse}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Страна</TableCell>
                        <TableCell>{saleData.sale.country}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Регион / Область</TableCell>
                        <TableCell>{saleData.sale.region} {saleData.sale.oblast}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            {saleData.report ? <ReportData /> : null}
        </Box>
    )
}

const ReportData = () => {

    const saleData = useSelector((state) => state.sales.sale)

    const ReportRow = ({data}) => {

        //console.log(data)

        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                    {data.type}
                </TableCell>
                <TableCell>
                    {data.oper_name}
                </TableCell>
                <TableCell>
                    {data.office}
                </TableCell>
                <TableCell>
                    {data.retail_amount}
                </TableCell>
                <TableCell sx={{backgroundColor: data.sign === '+' ? lime[500] : red[500]}}></TableCell>
                <TableCell>
                    {data.delivery_rub}
                </TableCell>
                <TableCell>
                    {data.ppvz_for_pay}
                </TableCell>
                <TableCell>
                    {data.ppvz_reward}
                </TableCell>
                <TableCell>
                    {data.penalty}
                </TableCell>
                <TableCell>
                    {data.additional_payment}
                </TableCell>
                <TableCell>
                    {data.bonus_type}
                </TableCell>
            </TableRow>
        )
    }

    return (
        <>
            <Typography variant="h5" sx={{mt: 3}}>Данные из отчёта</Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Тип</TableCell>
                            <TableCell>Операция</TableCell>
                            <TableCell>Склад</TableCell>
                            <TableCell>Сумма продаж (возвратов)</TableCell>
                            <TableCell></TableCell>
                            <TableCell>Логистика</TableCell>
                            <TableCell>К перечислению</TableCell>
                            <TableCell>Возмещение за выдачу и возврат</TableCell>
                            <TableCell>Штрафы</TableCell>
                            <TableCell>Доплаты</TableCell>
                            <TableCell>Обоснование штрафов и доплат</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {saleData.report.map((item, i) => <ReportRow key={i} data={item} />)}
                    </TableBody>
                </Table>
        </>
    )
}