import { Box, Paper } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getGraphHome } from "../../store/shops/graphics/grahicHome"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line } from "react-chartjs-2";
import Preloader from "../../components/Preloader"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
);

const HomeGraph = () => {

    const dispatch = useDispatch()

    const graph = useSelector((state) => state.grahicHome.data)
    const type = useSelector((state) => state.graphicType.value)

    const [wbIndex, setWbIndex] = useState(null)
    const [ozIndex, setOzIndex] = useState(null)

    const [labels, setLables] = useState([])
    const [values, setValues] = useState(null)
    const [min, setMin] = useState(0)
    const [max, setMax] = useState(100)

    useEffect(() => {
        dispatch(getGraphHome())
    }, [dispatch])

    useEffect(() => {
        if (graph && type !== 'percent') {
            setLables(graph.labels)
            setValues(graph[type])

            setMax(graph.extremums[type][1])
            setMin(graph.extremums[type][0])

            if (graph.shops) {
                if (graph.shops.wbIndex !== null) {
                    setWbIndex(graph.shops.wbIndex)
                }
                if (graph.shops.ozIndex !== null) {
                    setOzIndex(graph.shops.ozIndex)
                }
            }
        }
    }, [type, graph])

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    maxRotation: 45,
                    minRotation: 45
                }
            }
        },
        plugins: {
            legend: {
                position: "top",
            },
            tooltip: {
                callbacks: {
                    labelTextColor: function(context) {
                        return '#fff';
                    }
                }
            },
            annotation: {
                annotations: {
                    min : {
                        type: 'line',
                        borderColor: '#de401f',
                        borderWidth: 1,
                        borderDash: [3, 3],
                        label: {
                          backgroundColor: 'white',
                          color: '#de401f',
                          content: `Минимум — ${min.toLocaleString('ru-RU')}`,
                          display: true,
                          position: 'start'
                        },
                        scaleID: 'y',
                        value: min
                    },
                    max : {
                        type: 'line',
                        borderColor: '#21a74e',
                        borderWidth: 1,
                        borderDash: [3, 3],
                        label: {
                          backgroundColor: 'white',
                          color: '#21a74e',
                          content: `Максимум — ${max.toLocaleString('ru-RU')}`,
                          display: true,
                          position: 'start'
                        },
                        scaleID: 'y',
                        value: max
                    },
                    wb : {
                        type: 'line',
                        borderColor: '#9c36b5',
                        borderWidth: 1,
                        borderDash: [3, 3],
                        label: {
                          backgroundColor: 'white',
                          color: '#9c36b5',
                          content: `+ WB`,
                          display: true,
                          position: '100%'
                        },
                        xMin: wbIndex !== null ? wbIndex : 0,
                        xMax: wbIndex !== null ? wbIndex : 0,
                        display: wbIndex !== null
                    },
                    oz : {
                        type: 'line',
                        borderColor: '#00bfff',
                        borderWidth: 1,
                        borderDash: [3, 3],
                        label: {
                          backgroundColor: 'white',
                          color: '#00bfff',
                          content: `+ OZON`,
                          display: true,
                          position: '85%'
                        },
                        xMin: ozIndex !== null ? ozIndex : 0,
                        xMax: ozIndex !== null ? ozIndex : 0,
                        display: ozIndex !== null
                    },
                }
            }
        },
    };
        
    const data = {
        labels,
        datasets: [
            {
                label: "Значение",
                cubicInterpolationMode: "monotone",
                data: labels.map((item, i) => values[item].value),
                borderColor: "rgb(190, 215, 48)",
                //backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Среднее",
                cubicInterpolationMode: "monotone",
                data: labels.map((item, i) => values[item].middle),
                borderColor: "rgb(94, 129, 172)",
                borderDash: [2, 2],
                borderWidth: 2
                //backgroundColor: "rgba(255, 99, 132, 0.5)",
            }
        ],
    };

    return (
        <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Paper sx={{height: 400, p: 3, position: 'relative'}} elevation={0}>
                {graph && values ? <Line style={{width: '100%'}} options={options} data={data} /> : <Preloader />}
            </Paper>
        </Box>
    )
}

export default HomeGraph